import { CustomDate } from '@maw/utility';
import { PencilIcon, TrashIcon } from '@heroicons/react/20/solid';
import { ENV } from '../util/constant';
import placeholderAvatar from '../assets/generic-avatar.png';
import placeholderImage from '../assets/placeholder-image.png';
import IconButton from './IconButton';
import { useNavigate } from 'react-router-dom';

type BlogCardProps = {
  showCard?: 'all' | 'delete' | 'view';
  onDelete?: () => void;
  className?: string;
};

export default function BlogCard({
  coverImage,
  author,
  showCard,
  onDelete,
  className,
  translation,
}: Omit<BlogPost<'translation'>, 'content'> & BlogCardProps) {
  const navigate = useNavigate();
  return (
    <figure
      className={`${
        className && className
      } relative w-full md:w-96 h-80 rounded-lg hover:shadow-lg overflow-hidden`}
      style={{
        backgroundImage: `url(${
          !coverImage ? placeholderImage : `${ENV.imageBaseUrl}/${coverImage}`
        })`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundColor: 'gray',
        backgroundRepeat: 'no-repeat',
        backgroundBlendMode: 'overlay',
      }}
    >
      <figcaption className='absolute bottom-0 left-0 m-auto text-white p-4 bg-gray-600 bg-opacity-60 w-full'>
        <div className='flex flex-col justify-self-start md:gap-x-2 mb-2 md:flex-row md:items-center'>
          {translation && translation.publishedAt && (
            <p className='w-fit'>
              {CustomDate.formatDateWithDay(translation.publishedAt)}
            </p>
          )}
          <div className='w-10 h-10 rounded-full'>
            <img
              className='w-full h-full object-cover rounded-full'
              src={`${
                author.avatar
                  ? `${ENV.imageBaseUrl}/${author.avatar}`
                  : placeholderAvatar
              }`}
              alt={`${author.firstName} ${author.lastName}`}
            />
          </div>
          <p className='w-fit'>{`${author.firstName} ${author.lastName}`}</p>
        </div>
        <h1 className='font-bold w-fit md:text-2xl text-xl'>
          {translation.title}
        </h1>
      </figcaption>
      {showCard && (
        <div className='flex absolute right-4 top-2'>
          {showCard === 'all' && (
            <div className='flex gap-x-2 justify-center items-center rounded-3xl'>
              <IconButton
                onClick={onDelete}
                icon={
                  <TrashIcon
                    className='bg-gray-500 bg-opacity-50 rounded-full h-8 w-8 p-1 text-red-500 hover:text-red-600 hover:bg-white transition-colors'
                    aria-hidden='true'
                  />
                }
              />
              <IconButton
                onClick={() =>
                  navigate(`/dashboard/blog-posts/${translation.slug}`)
                }
                icon={
                  <PencilIcon
                    className='bg-gray-500 bg-opacity-50 rounded-full h-8 w-8 p-1 text-indigo-500 hover:text-indigo-600 hover:bg-white transition-colors'
                    aria-hidden='true'
                  />
                }
              />
            </div>
          )}
          {showCard === 'delete' && coverImage && (
            <IconButton
              onClick={onDelete}
              icon={
                <TrashIcon
                  className='bg-gray-500 bg-opacity-50 rounded-full h-8 w-8 p-1 text-red-500 hover:text-red-600 hover:bg-white transition-colors'
                  aria-hidden='true'
                />
              }
            />
          )}
          {showCard === 'view' && (
            <IconButton
              onClick={() =>
                navigate(`/dashboard/blog-posts/${translation.slug}`)
              }
              className='bg-black rounded-full w-8 h-8 hover:bg-white transition-colors'
              icon={
                <PencilIcon
                  className='bg-gray-500 bg-opacity-50 rounded-full h-8 w-8 p-1 text-indigo-500 hover:text-indigo-600 hover:bg-white transition-colors'
                  aria-hidden='true'
                />
              }
            />
          )}
        </div>
      )}
    </figure>
  );
}
