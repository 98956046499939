var CustomDate = /** @class */ (function () {
    function CustomDate() {
    }
    CustomDate.getDay = function (date) {
        return new Date(date).getDate();
    };
    CustomDate.getMonth = function (date, options) {
        if (options === void 0) { options = 'short'; }
        return new Date(date).toLocaleDateString('en-FI', { month: options });
    };
    CustomDate.getHours = function (date) {
        return new Date(date).getHours();
    };
    CustomDate.getMinutes = function (date) {
        return new Date(date).getMinutes().toString().padStart(2, '0');
    };
    CustomDate.getFormat = function (date) {
        return CustomDate.getHours(date) >= 12 ? 'pm' : 'am';
    };
    CustomDate.getYear = function (date, options) {
        if (options === void 0) { options = '2-digit'; }
        return new Date(date).toLocaleDateString('en-FI', { year: options });
    };
    CustomDate.formatCustomDate = function (date) {
        var formattedDate = "".concat(CustomDate.getMonth(date), " ").concat(CustomDate.getDay(date), ", ").concat(CustomDate.getYear(date, 'numeric'), " @").concat(CustomDate.getHours(date), ":").concat(CustomDate.getMinutes(date));
        return formattedDate;
    };
    CustomDate.formatDate = function (date) {
        return (date &&
            new Date(date).toLocaleDateString('en-FI', {
                year: 'numeric',
                month: 'long',
            }));
    };
    CustomDate.formatDateWithDay = function (date) {
        return (date &&
            new Date(date).toLocaleDateString('en-FI', {
                year: '2-digit',
                month: 'short',
                day: '2-digit',
            }));
    };
    CustomDate.getToday = function () {
        return new Date().toISOString().slice(0, 16);
    };
    CustomDate.getDateTimeLocal = function (date) {
        var year = new Date(date).toLocaleString('en-FI', {
            year: 'numeric',
        });
        var month = new Date(date).toLocaleString('en-FI', {
            month: '2-digit',
        });
        var day = new Date(date).toLocaleString('en-FI', { day: '2-digit' });
        var hour = new Date(date).toLocaleString('en-FI', {
            hour: '2-digit',
        });
        var minute = new Date(date)
            .toLocaleString('en-FI', {
            minute: '2-digit',
        })
            .toString()
            .padStart(2, '0');
        return "".concat(year, "-").concat(month, "-").concat(day, "T").concat(hour, ":").concat(minute);
    };
    CustomDate.getDayMonthYearHyphenated = function (date) {
        var year = new Date(date).toLocaleString('en-FI', {
            year: 'numeric',
        });
        var month = new Date(date).toLocaleString('en-FI', {
            month: '2-digit',
        });
        var day = new Date(date).toLocaleString('en-FI', { day: '2-digit' });
        return "".concat(day, "-").concat(month, "-").concat(year);
    };
    return CustomDate;
}());
export { CustomDate };
