import App from './App';
import { AuthContext } from './store/auth';
import { useAuth } from './hooks/auth';
import { QueryClientProvider } from '@tanstack/react-query';
import { queryClient } from './util/request';

export default function Wrapper() {
  const { user, token, login, logout } = useAuth();

  return (
    <AuthContext.Provider value={{ user, token, login, logout }}>
      <QueryClientProvider client={queryClient}>
        <App />
      </QueryClientProvider>
    </AuthContext.Provider>
  );
}
