import { useState } from 'react';
import { deleteById, queryClient } from '../util/request';
import { isAuthorizedSuper } from '../util/helper';

export default function useOperation() {
  const [show, setShow] = useState<boolean>(false);
  const [id, setId] = useState<string>('0');

  async function handleDelete(resource: string, queryKey: any[]) {
    setShow(false);
    await deleteById(resource);
    await queryClient.invalidateQueries({ queryKey });
  }

  function closeModal() {
    setShow(false);
  }

  function markObjectForDeletion(id: string) {
    if (isAuthorizedSuper()) {
      setShow(true);
      setId(id);
    }
  }

  return { show, id, handleDelete, markObjectForDeletion, closeModal };
}
