import {
  LoaderFunction,
  LoaderFunctionArgs,
  useActionData,
  useParams,
} from 'react-router-dom';
import Card from '../../../Card';
import { getById, queryClient } from '../../../../util/request';
import Container from '../../../Container';
import Alert from '../../../Alert';
import { useRedirect } from '../../../../hooks/redirect';
import { useQuery } from '@tanstack/react-query';
import useOperation from '../../../../hooks/operation';
import Modal from '../../../Modal';
import AssetCard from '../../../AssetCard';

export default function AssetDetails() {
  const actionData: any = useActionData();
  const params = useParams();
  const { data, isLoading } = useQuery({
    queryKey: ['assets', params.id],
    queryFn: ({ signal }) =>
      getById({ signal, resource: `assets/${params.id}` }),
  });

  const asset: Asset = data?.data;

  const { id, show, handleDelete, markObjectForDeletion, closeModal } =
    useOperation();

  useRedirect(actionData, 'assets', true);

  return (
    <>
      {!isLoading && (
        <Container>
          <Modal
            show={show}
            onClose={closeModal}
            onAction={() => handleDelete(`assets/${id}`, ['assets', id])}
          />
          <Alert data={actionData} />
          <Container.Heading>Asset Details</Container.Heading>
          <Container.SubHeading>
            Authorized user can write the file.
          </Container.SubHeading>
          <Card>
            {asset && asset.url && (
              <AssetCard
                className='my-4 w-full h-96'
                id={asset.id}
                onDelete={() => markObjectForDeletion(asset.id)}
                allowDelete={asset.usageCount === 0}
                key={asset.id}
                usageCount={asset.usageCount}
                url={asset.url}
              />
            )}
          </Card>
        </Container>
      )}
    </>
  );
}

export async function loader({
  params,
}: LoaderFunctionArgs): Promise<LoaderFunction> {
  return await queryClient.fetchQuery({
    queryKey: ['assets', params.id],
    queryFn: ({ signal }) =>
      getById({ signal, resource: `assets/${params.id}` }),
  });
}
