import { PencilIcon, TrashIcon } from '@heroicons/react/20/solid';
import IconButton from '../../../IconButton';
import Container from '../../../Container';
import { getResources, queryClient } from '../../../../util/request';
import Modal from '../../../Modal';
import { isAuthorizedSuperAdmin } from '../../../../util/helper';
import { Link, LoaderFunction } from 'react-router-dom';
import Badge from '../../../Badge';
import { CustomDate } from '@maw/utility';
import useOperation from '../../../../hooks/operation';
import { useQuery } from '@tanstack/react-query';

export default function Recruitments() {
  const { id, show, handleDelete, markObjectForDeletion, closeModal } =
    useOperation();
  const { data } = useQuery({
    queryKey: ['recruitments'],
    queryFn: ({ signal }) => getResources({ signal, url: 'recruitments' }),
  });
  const recruitments: Recruitment[] = data?.data;

  return (
    <>
      <Modal
        show={show}
        onClose={closeModal}
        onAction={() => handleDelete(`recruitments/${id}`, ['recruitments'])}
      />
      <Container>
        {recruitments && recruitments.length > 0 ? (
          <>
            <Container.Heading>Jobs</Container.Heading>
            <Container.SubHeading>
              List of all currently available openings.
            </Container.SubHeading>
            <div className='overflow-x-auto rounded-md'>
              <table className='min-w-full divide-y divide-gray-200 table-auto'>
                <thead className='bg-gray-100'>
                  <tr>
                    <th
                      scope='col'
                      className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'
                    >
                      #
                    </th>
                    <th
                      scope='col'
                      className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'
                    >
                      Title
                    </th>
                    <th
                      scope='col'
                      className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'
                    >
                      Post status
                    </th>
                    <th
                      scope='col'
                      className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'
                    >
                      Expires On
                    </th>
                    <th
                      scope='col'
                      className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'
                    >
                      Description
                    </th>
                    {isAuthorizedSuperAdmin() && (
                      <th
                        scope='col'
                        className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'
                      >
                        Operation
                      </th>
                    )}
                  </tr>
                </thead>
                <tbody className='bg-white divide-y divide-gray-200 p-0'>
                  {recruitments.map((recruitment, index) => (
                    <tr
                      key={recruitment.id}
                      className='hover:bg-gray-100 transition-all'
                    >
                      <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-600'>
                        {index + 1}
                      </td>
                      <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-600'>
                        {recruitment.title}
                      </td>
                      <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-600'>
                        <Badge status={recruitment.postStatus}>
                          {recruitment.postStatus}
                        </Badge>
                      </td>
                      <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-600'>
                        {CustomDate.formatCustomDate(recruitment.expiresAt)}
                      </td>
                      <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-600'>
                        {`${recruitment.description.substring(0, 30)}...`}
                      </td>
                      {isAuthorizedSuperAdmin() && (
                        <td className='px-6 py-4 flex gap-2'>
                          <Link
                            to={`/dashboard/recruitments/${recruitment.id}`}
                          >
                            <IconButton
                              icon={
                                <PencilIcon
                                  className='h-5 w-5 text-indigo-400'
                                  aria-hidden='true'
                                />
                              }
                            />
                          </Link>
                          <IconButton
                            onClick={() =>
                              markObjectForDeletion(recruitment.id)
                            }
                            icon={
                              <TrashIcon
                                className='h-5 w-5 text-red-400'
                                aria-hidden='true'
                              />
                            }
                          />
                        </td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </>
        ) : (
          <Container.Heading>No data</Container.Heading>
        )}
      </Container>
    </>
  );
}

export async function loader(): Promise<LoaderFunction> {
  return await queryClient.fetchQuery({
    queryKey: ['recruitments'],
    queryFn: ({ signal }) => getResources({ signal, url: 'recruitments' }),
  });
}
