import { Navigate, Outlet } from 'react-router-dom';
import Sidebar from './Sidebar';
import Footer from './Footer';
import NavHeader from './NavHeader';
import { isAuthorized } from '../util/helper';
import { useState } from 'react';

export default function Layout() {
  const [show, setShow] = useState<boolean>(false);

  function toggleSidebarDisplay() {
    setShow(!show);
  }

  return (
    <>
      {!isAuthorized() && <Navigate to='/' replace={true} />}
      <main className='flex h-screen'>
        {show && <Sidebar className='block md:hidden w-60 z-10' />}
        <Sidebar className='hidden md:block w-60 z-10' />
        <div className='flex flex-col flex-1 overflow-hidden z-10'>
          <NavHeader
            className='py-2 px-6'
            toggleSidebarDisplay={toggleSidebarDisplay}
          />
          <div className='flex-1 p-4 overflow-y-scroll'>
            <Outlet />
          </div>
          <Footer />
        </div>
      </main>
    </>
  );
}
