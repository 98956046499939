import { PencilIcon, TrashIcon } from '@heroicons/react/20/solid';
import IconButton from '../../../IconButton';
import Container from '../../../Container';
import { getResources, queryClient } from '../../../../util/request';
import Modal from '../../../Modal';
import { isAuthorizedSuperAdmin } from '../../../../util/helper';
import { Link, LoaderFunction } from 'react-router-dom';
import Badge from '../../../Badge';
import useOperation from '../../../../hooks/operation';
import { useQuery } from '@tanstack/react-query';

export default function Services() {
  const { data } = useQuery({
    queryKey: ['services'],
    queryFn: ({ signal }) => getResources({ signal, url: 'services' }),
  });
  const services: Service[] = data?.data;
  const { id, show, handleDelete, markObjectForDeletion, closeModal } =
    useOperation();

  return (
    <Container>
      <Modal
        show={show}
        onClose={closeModal}
        onAction={() => handleDelete(`services/${id}`, ['services'])}
      />
      {services && services.length > 0 ? (
        <>
          <Container.Heading>Services</Container.Heading>
          <Container.SubHeading>
            List of available services on offer.
          </Container.SubHeading>
          <div className='overflow-x-auto rounded-md'>
            <table className='min-w-full divide-y divide-gray-200 table-auto'>
              <thead className='bg-gray-100'>
                <tr>
                  <th
                    scope='col'
                    className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'
                  >
                    #
                  </th>
                  <th
                    scope='col'
                    className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'
                  >
                    Title
                  </th>
                  <th
                    scope='col'
                    className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'
                  >
                    Sub title
                  </th>
                  <th
                    scope='col'
                    className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'
                  >
                    Status
                  </th>
                  <th
                    scope='col'
                    className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'
                  >
                    Category
                  </th>
                  {isAuthorizedSuperAdmin() && (
                    <th
                      scope='col'
                      className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'
                    >
                      Operation
                    </th>
                  )}
                </tr>
              </thead>
              <tbody className='bg-white divide-y divide-gray-200 p-0'>
                {services.length > 0 &&
                  services.map((service, index) => (
                    <tr
                      key={service.id}
                      className='hover:bg-gray-100 transition-all'
                    >
                      <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-600'>
                        {(index += 1)}
                      </td>
                      <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-600'>
                        {`${service.translations[0].title.substring(0, 20)}...`}
                      </td>
                      <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-600'>
                        {`${service.translations[0].subTitle.substring(
                          0,
                          20
                        )}...`}
                      </td>
                      <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-600'>
                        <Badge status={service.translations[0].postStatus}>
                          {service.translations[0].postStatus}
                        </Badge>
                      </td>
                      <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-600'>
                        {service.translations[0].serviceCategory}
                      </td>
                      {isAuthorizedSuperAdmin() && (
                        <td className='px-6 py-4 flex gap-2'>
                          <Link to={`/dashboard/services/${service.id}`}>
                            <IconButton
                              icon={
                                <PencilIcon
                                  className='h-5 w-5 text-indigo-400'
                                  aria-hidden='true'
                                />
                              }
                            />
                          </Link>
                          <IconButton
                            onClick={() => markObjectForDeletion(service.id)}
                            icon={
                              <TrashIcon
                                className='h-5 w-5 text-red-400'
                                aria-hidden='true'
                              />
                            }
                          />
                        </td>
                      )}
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </>
      ) : (
        <Container.Heading>No data</Container.Heading>
      )}
    </Container>
  );
}

export async function loader(): Promise<LoaderFunction> {
  return await queryClient.fetchQuery({
    queryKey: ['services'],
    queryFn: ({ signal }) => getResources({ signal, url: 'services' }),
  });
}
