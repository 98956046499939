import Container from '../../../Container';
import { getPaginatedData, queryClient } from '../../../../util/request';
import Modal from '../../../Modal';
import useOperation from '../../../../hooks/operation';
import { useState } from 'react';
import Pagination from '../../../Pagination';
import { useQuery } from '@tanstack/react-query';
import AssetCard from '../../../AssetCard';
import { LoaderFunction } from 'react-router-dom';

export default function Assets() {
  const [currentPage, setCurrentPage] = useState<number>(1);

  const { data, isLoading } = useQuery({
    queryKey: ['assets', currentPage],
    queryFn: ({ signal }) =>
      getPaginatedData({
        signal,
        currentPage,
        perPage: 20,
        resource: 'assets/paginated/all',
      }),
  });

  const paginatedAssets = data?.data;

  const handlePageChange = (
    direction: 'prev' | 'next' | 'specific',
    pageNumber?: number
  ) => {
    if (direction === 'specific' && pageNumber) {
      setCurrentPage(pageNumber);
    } else if (direction === 'prev') {
      setCurrentPage(currentPage - 1);
    } else if (direction === 'next') {
      setCurrentPage(currentPage + 1);
    }
  };

  const { id, show, handleDelete, markObjectForDeletion, closeModal } =
    useOperation();

  return (
    <>
      {!isLoading && (
        <Container>
          <Modal
            show={show}
            onClose={closeModal}
            onAction={() =>
              handleDelete(`assets/${id}`, ['assets', currentPage])
            }
          />
          {paginatedAssets.data && paginatedAssets.data.length ? (
            <>
              <Container.Heading>Assets</Container.Heading>
              <Container.SubHeading>List of all assets.</Container.SubHeading>
              <div className='flex gap-4 flex-wrap'>
                {paginatedAssets.data.map((asset: Asset) => (
                  <AssetCard
                    key={asset.id}
                    id={asset.id}
                    usageCount={asset.usageCount}
                    allowDelete={asset.usageCount === 0}
                    allowView={true}
                    onDelete={() => markObjectForDeletion(asset.id)}
                    url={asset.url}
                  />
                ))}
              </div>
              {paginatedAssets &&
                paginatedAssets.total > paginatedAssets.perPage && (
                  <Pagination
                    onHandlePageChange={handlePageChange}
                    total={paginatedAssets.total}
                    perPage={paginatedAssets.perPage}
                    currentPage={paginatedAssets.currentPage}
                  />
                )}
            </>
          ) : (
            <Container.Heading>No data</Container.Heading>
          )}
        </Container>
      )}
    </>
  );
}

export async function loader(): Promise<LoaderFunction> {
  const page = 1;
  return await queryClient.fetchQuery({
    queryKey: ['assets', page],
    queryFn: ({ signal }) =>
      getPaginatedData({
        signal,
        currentPage: page,
        perPage: 20,
        resource: 'assets/paginated/all',
      }),
  });
}
