import { TrashIcon } from '@heroicons/react/20/solid';
import { ENV } from '../util/constant';
import placeholderImage from '../assets/placeholder-image.png';
import IconButton from './IconButton';
import { UserIcon } from '@heroicons/react/24/outline';

type ContactCardProps = {
  allowDelete?: boolean;
  onDelete?: () => void;
  className?: string;
};

export default function TestimonialCard({
  photo,
  firstName,
  lastName,
  allowDelete,
  onDelete,
  className,
}: Pick<Testimonial, 'id' | 'firstName' | 'lastName' | 'photo'> &
  ContactCardProps) {
  return (
    <figure
      className={`${
        className && className
      } relative w-full md:w-96 h-80 rounded-lg hover:shadow-lg overflow-hidden`}
    >
      {photo && (
        <div className='rounded-lg'>
          <img
            src={`${photo ? `${ENV.imageBaseUrl}/${photo}` : placeholderImage}`}
            alt={`${firstName} ${lastName}`}
            className='object-cover w-full rounded-t-lg'
          />
        </div>
      )}
      <figcaption className='absolute bottom-0 right-0 left-0 bg-indigo-500 bg-opacity-60 text-white p-4 h-fit'>
        <div className='flex items-center gap-x-2 mb-2'>
          <UserIcon className='h-7 w-7' aria-hidden='true' />
          <p className='italic'>{`${firstName} ${lastName}`}</p>
        </div>
      </figcaption>
      {allowDelete && photo && (
        <div className='absolute right-4 top-2'>
          <IconButton
            onClick={onDelete}
            icon={
              <TrashIcon
                className='bg-gray-500 bg-opacity-50 rounded-full h-8 w-8 p-1 text-red-500 hover:text-red-600 hover:bg-white transition-colors'
                aria-hidden='true'
              />
            }
          />
        </div>
      )}
    </figure>
  );
}
