import PieChart from '../components/widgets/PieChart';
import Card from './Card';
import Container from './Container';

export default function Dashboard() {
  const data = [
    ['Task', 'Hours per Day'],
    ['Work', 11],
    ['Eat', 2],
    ['Commute', 2],
    ['Watch TV', 2],
    ['Sleep', 7],
  ];

  const options = {
    title: 'My Daily Activities',
    is3D: false,
  };

  return (
    <Container>
      <Container.Heading>Analytics</Container.Heading>
      <Container.SubHeading>
        This dashboard provides an overview of all activities across the board.
      </Container.SubHeading>
      <Card>
        <Card.Header>My daily activities</Card.Header>
        <PieChart width={350} height={350} data={data} options={options} />
      </Card>
    </Container>
  );
}
