import {
  CButton,
  FormGroup,
  disableButton,
  handleNavigationStatus,
} from '@maw/utility';
import {
  ActionFunction,
  ActionFunctionArgs,
  Form,
  LoaderFunction,
  LoaderFunctionArgs,
  useActionData,
  useNavigation,
  useParams,
} from 'react-router-dom';
import Card from '../../../Card';
import { getById, queryClient, upsertResource } from '../../../../util/request';
import Container from '../../../Container';
import Alert from '../../../Alert';
import { useRedirect } from '../../../../hooks/redirect';
import { postStatus } from '../../../../util/constant';
import { useQuery } from '@tanstack/react-query';
import { disableWhenLoading } from '../../../../util/helper';
import HiddenInput from '../../../HiddenInput';
import ImageCard from '../../../ImageCard';
import useOperation from '../../../../hooks/operation';
import Modal from '../../../Modal';
import ErrorAlert from '../../../ErrorAlert';

export default function CompanyAboutDetails() {
  const navigation = useNavigation();
  const params = useParams();
  const { data } = useQuery({
    queryKey: ['company-abouts', params.id],
    queryFn: ({ signal }) =>
      getById({ signal, resource: `company-abouts/${params.id}` }),
  });
  const actionData: any = useActionData();

  useRedirect(actionData, 'company-abouts', true);

  const companyAbout: CompanyAbout = data?.data;

  const { id, show, handleDelete, markObjectForDeletion, closeModal } =
    useOperation();

  return (
    <Container>
      <Modal
        show={show}
        onClose={closeModal}
        onAction={() =>
          handleDelete(`company-abouts/${id}/images/delete`, [
            'company-abouts',
            id,
          ])
        }
      />
      <ErrorAlert data={actionData} />
      <Alert data={actionData} />
      <Container.Heading>Company About Details</Container.Heading>
      <Container.SubHeading>
        Authorized user can write the file.
      </Container.SubHeading>
      {companyAbout && companyAbout.coverImage && (
        <ImageCard
          className='my-4'
          translations={companyAbout.translations}
          id={companyAbout.id}
          onDelete={() => markObjectForDeletion(companyAbout.id)}
          allowDelete
          key={companyAbout.id}
          coverImage={companyAbout.coverImage}
        />
      )}
      {companyAbout && (
        <Card>
          <Card.Header>Company About Details</Card.Header>
          {companyAbout && (
            <Form method='post' encType='multipart/form-data' noValidate>
              <FormGroup>
                <div className='flex flex-col gap-x-2 md:flex-row md:space-x-2'>
                  <div className='flex-1'>
                    <p className='mb-8 text-gray-500 font-bold'>English</p>
                    <FormGroup.Input
                      labelclassname='bg-gray-100'
                      type='text'
                      id='translations[0][title][en]'
                      title='Title'
                      placeholder='Enter title'
                      defaultValue={companyAbout.translations[0].title}
                      data={actionData}
                      disabled={disableWhenLoading(navigation)}
                    />
                    <FormGroup.TextArea
                      labelclassname='bg-gray-100'
                      id='translations[0][description][en]'
                      title='Description'
                      placeholder='Enter description'
                      defaultValue={companyAbout.translations[0].description}
                      data={actionData}
                      disabled={disableWhenLoading(navigation)}
                    />
                  </div>
                  <div className='flex-1'>
                    <p className='mb-8 text-gray-500 font-bold'>Finnish</p>
                    <FormGroup.Input
                      labelclassname='bg-gray-100'
                      type='text'
                      id='translations[0][title][fi]'
                      title='Title'
                      placeholder='Enter title'
                      defaultValue={companyAbout.translations[1].title}
                      data={actionData}
                      disabled={disableWhenLoading(navigation)}
                    />
                    <FormGroup.TextArea
                      labelclassname='bg-gray-100'
                      id='translations[0][description][fi]'
                      title='Description'
                      placeholder='Enter description'
                      defaultValue={companyAbout.translations[1].description}
                      data={actionData}
                      disabled={disableWhenLoading(navigation)}
                    />
                  </div>
                </div>
                <div className='flex-1'>
                  <p className='mb-8 text-gray-500 font-bold'>Common inputs</p>
                  <FormGroup.Input
                    labelclassname='bg-gray-100'
                    type='file'
                    id='cover_image'
                    title='Cover image (MaxHeight: 500, MaxWidth: 800)'
                    accept='.jpeg, .png, .jpg, .svg'
                    data={actionData}
                    disabled={disableWhenLoading(navigation)}
                  />
                  <FormGroup.Select
                    labelclassname='bg-gray-100'
                    className='flex-1'
                    id='translations[0][post_status]'
                    title='Post status'
                    defaultValue={companyAbout.translations[0].postStatus}
                    data={actionData}
                    disabled={disableWhenLoading(navigation)}
                  >
                    {postStatus.map((post) => (
                      <option value={post.value} key={post.value}>
                        {post.name}
                      </option>
                    ))}
                  </FormGroup.Select>
                </div>
                <HiddenInput />
                <CButton
                  rounded
                  variant='primary'
                  type='submit'
                  disabled={disableButton(navigation)}
                  className='mt-6'
                >
                  {handleNavigationStatus(navigation, 'Submit')}
                </CButton>
              </FormGroup>
            </Form>
          )}
        </Card>
      )}
    </Container>
  );
}

export async function loader({
  params,
}: LoaderFunctionArgs): Promise<LoaderFunction> {
  return await queryClient.fetchQuery({
    queryKey: ['company-abouts', params.id],
    queryFn: ({ signal }) =>
      getById({ signal, resource: `company-abouts/${params.id}` }),
  });
}

export async function action({
  request,
  params,
}: ActionFunctionArgs): Promise<ActionFunction> {
  await queryClient.invalidateQueries({ queryKey: ['company-abouts'] });
  return await upsertResource({
    request,
    resource: `company-abouts/${params.id}`,
    isFormData: true,
    action: 'update',
  });
}
