var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { STATUS_CODE } from './constants/status';
var ErrorManager = /** @class */ (function () {
    function ErrorManager(isError, errorResponse) {
        if (isError === void 0) { isError = false; }
        this.isError = isError;
        this.errorResponse = errorResponse !== null && errorResponse !== void 0 ? errorResponse : {
            error: { message: {} },
            statusCode: 0,
        };
    }
    ErrorManager.prototype.hasError = function (data, id) {
        var modifiedMessage = __assign({}, data);
        this.errorResponse = modifiedMessage;
        var assetErrors = [];
        if (modifiedMessage && modifiedMessage.error) {
            for (var key in modifiedMessage.error.message) {
                if (key.startsWith("".concat(id, ".")) && !key.startsWith('translations.')) {
                    var fieldName = key.split('.')[0];
                    assetErrors.push.apply(assetErrors, modifiedMessage.error.message[key]);
                    modifiedMessage.error.message[fieldName] = assetErrors;
                    delete modifiedMessage.error.message[key];
                }
                else if (key.startsWith('translations.')) {
                    assetErrors.push.apply(assetErrors, modifiedMessage.error.message[key]);
                }
            }
            this.isError =
                (modifiedMessage === null || modifiedMessage === void 0 ? void 0 : modifiedMessage.error.message) &&
                    modifiedMessage.error.message[id] &&
                    modifiedMessage.statusCode === STATUS_CODE.unprocessableContent &&
                    Object.keys(modifiedMessage.error.message).includes(id);
        }
    };
    ErrorManager.prototype.getErrorStyle = function () {
        return this.isError ? 'ring-1 ring-red-400' : undefined;
    };
    ErrorManager.prototype.getErrorMessage = function (id) {
        if (this.isError &&
            this.errorResponse &&
            this.errorResponse.error &&
            this.errorResponse.error.message) {
            var errorMessage = this.errorResponse.error.message;
            if (errorMessage && errorMessage[id] && Array.isArray(errorMessage[id])) {
                var errorList = errorMessage[id].map(function (el) { return (_jsx("li", __assign({ className: 'text-red-400 text-sm' }, { children: el }), el)); });
                return _jsx("ul", __assign({ className: 'mb-6' }, { children: errorList }));
            }
        }
        return '';
    };
    return ErrorManager;
}());
export { ErrorManager };
