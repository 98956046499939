type CardProps = {
  className?: string;
  children: React.ReactNode;
};

function Header({ className, children }: CardProps) {
  return (
    <div
      className={`${
        className && className
      } bg-gray-200 p-4 rounded-t-md ring-1 ring-gray-400 -mx-4 -mt-8 mb-8`}
    >
      {children}
    </div>
  );
}

Card.Header = Header;

export default function Card({ children, className }: CardProps) {
  return (
    <div
      className={`rounded-md ring-1 ring-gray-400 py-8 px-4 shadow-md m-auto ${
        className && className
      }`}
    >
      {children}
    </div>
  );
}
