import Chart from 'react-google-charts';

type PieChartProps = {
  data: Array<Array<string | number>>;
  options: { title: string; is3D: boolean };
  className?: string;
  width?: string | number;
  height?: string | number;
};

export default function PieChart({
  data,
  options,
  className,
  width,
  height,
}: PieChartProps) {
  return (
    <Chart
      className={`${className && className}`}
      chartType='PieChart'
      data={data}
      options={options}
      width={width}
      height={height}
    />
  );
}
