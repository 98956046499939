import { PencilIcon, TrashIcon } from '@heroicons/react/20/solid';
import IconButton from '../../../IconButton';
import Container from '../../../Container';
import { getResources, queryClient } from '../../../../util/request';
import Modal from '../../../Modal';
import {
  isAuthorizedSuper,
  isAuthorizedSuperAdmin,
} from '../../../../util/helper';
import { Link, LoaderFunction } from 'react-router-dom';
import Badge from '../../../Badge';
import useOperation from '../../../../hooks/operation';
import { useQuery } from '@tanstack/react-query';

export default function BlogCategories() {
  const { data } = useQuery({
    queryKey: ['blog-categories'],
    queryFn: ({ signal }) =>
      getResources({ signal, url: 'blog-categories/display/table' }),
  });
  const blogCategories: BlogCategory<'translations'>[] = data?.data;
  const { id, show, handleDelete, markObjectForDeletion, closeModal } =
    useOperation();

  return (
    <Container>
      <Modal
        show={show}
        onClose={closeModal}
        onAction={() =>
          handleDelete(`blog-categories/${id}`, ['blog-categories'])
        }
      />
      {blogCategories && blogCategories.length > 0 ? (
        <>
          <Container.Heading>Blog Categories</Container.Heading>
          <Container.SubHeading>
            List of all blog categories.
          </Container.SubHeading>
          <div className='overflow-x-auto rounded-md'>
            <table className='min-w-full divide-y divide-gray-200 table-auto'>
              <thead className='bg-gray-100'>
                <tr>
                  <th
                    scope='col'
                    className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'
                  >
                    #
                  </th>
                  <th
                    scope='col'
                    className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'
                  >
                    Name
                  </th>
                  <th
                    scope='col'
                    className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'
                  >
                    Status
                  </th>

                  {isAuthorizedSuperAdmin() && (
                    <th
                      scope='col'
                      className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'
                    >
                      Operation
                    </th>
                  )}
                </tr>
              </thead>
              <tbody className='bg-white divide-y divide-gray-200 p-0'>
                {blogCategories.map((blogCategory, index) => (
                  <tr
                    key={blogCategory.id}
                    className='hover:bg-gray-100 transition-all'
                  >
                    <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-600'>
                      {index + 1}
                    </td>
                    <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-600'>
                      {blogCategory.translations[0].name}
                    </td>
                    <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-600'>
                      <Badge status={blogCategory.translations[0].postStatus}>
                        {blogCategory.translations[0].postStatus}
                      </Badge>
                    </td>

                    {isAuthorizedSuperAdmin() && (
                      <td className='px-6 py-4 flex gap-2'>
                        <Link
                          to={`/dashboard/blog-categories/${blogCategory.translations[0].slug}`}
                        >
                          <IconButton
                            icon={
                              <PencilIcon
                                className='h-5 w-5 text-indigo-400'
                                aria-hidden='true'
                              />
                            }
                          />
                        </Link>
                        {isAuthorizedSuper() && (
                          <IconButton
                            onClick={() =>
                              markObjectForDeletion(
                                blogCategory.translations[0].slug
                              )
                            }
                            icon={
                              <TrashIcon
                                className='h-5 w-5 text-red-400'
                                aria-hidden='true'
                              />
                            }
                          />
                        )}
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </>
      ) : (
        <Container.Heading>No data</Container.Heading>
      )}
    </Container>
  );
}

export async function loader(): Promise<LoaderFunction> {
  return await queryClient.fetchQuery({
    queryKey: ['blog-categories'],
    queryFn: ({ signal }) =>
      getResources({ signal, url: 'blog-categories/display/table' }),
  });
}
