import {
  CButton,
  COUNTRIES,
  FormGroup,
  disableButton,
  handleNavigationStatus,
} from '@maw/utility';
import {
  ActionFunction,
  ActionFunctionArgs,
  Form,
  LoaderFunction,
  LoaderFunctionArgs,
  useActionData,
  useNavigation,
  useParams,
} from 'react-router-dom';
import Card from '../../../Card';
import { getById, queryClient, upsertResource } from '../../../../util/request';
import Container from '../../../Container';
import Alert from '../../../Alert';
import {
  ROLES,
  USER_STATUS,
  gender,
  permitStatus,
  roles,
  userStatus,
} from '../../../../util/constant';
import { disableWhenLoading, isAuthorizedSuper } from '../../../../util/helper';
import { useRedirect } from '../../../../hooks/redirect';
import { User } from '../../../../types/user';
import { useQuery } from '@tanstack/react-query';

export default function UserDetails() {
  const navigation = useNavigation();
  const params = useParams();
  const actionData: any = useActionData();
  const { data } = useQuery({
    queryKey: ['users', params.id],
    queryFn: ({ signal }) =>
      getById({ signal, resource: `users/${params.id}` }),
  });
  const user: User = data?.data;

  useRedirect(actionData, 'users', true);

  return (
    <Container>
      <Alert data={actionData} />
      <Container.Heading>User Details</Container.Heading>
      <Container.SubHeading>
        Authorized user can write the file.
      </Container.SubHeading>
      {user && (
        <Card>
          <Card.Header>{`${user.firstName} ${user.lastName}`}</Card.Header>
          <Form method='post' noValidate>
            <FormGroup>
              <div className='flex flex-col gap-x-2 md:flex-row md:space-x-2'>
                <FormGroup.Input
                  labelclassname='bg-gray-100'
                  className='flex-1'
                  type='text'
                  id='first_name'
                  title='First name'
                  placeholder='Enter first name'
                  defaultValue={user.firstName}
                  data={actionData}
                  disabled={disableWhenLoading(navigation)}
                />
                <FormGroup.Input
                  labelclassname='bg-gray-100'
                  className='flex-1'
                  type='text'
                  id='last_name'
                  title='Last name'
                  placeholder='Enter last name'
                  defaultValue={user.lastName}
                  data={actionData}
                  disabled={disableWhenLoading(navigation)}
                />
              </div>
              <div className='flex flex-col gap-x-2 md:flex-row md:space-x-2'>
                <FormGroup.Input
                  labelclassname='bg-gray-100'
                  className='flex-1'
                  type='text'
                  id='address'
                  title='Address'
                  placeholder='Enter your address'
                  defaultValue={user.address}
                  data={actionData}
                  disabled={disableWhenLoading(navigation)}
                />
                <FormGroup.Input
                  labelclassname='bg-gray-100'
                  className='flex-1'
                  type='text'
                  id='city'
                  title='City'
                  placeholder='Enter your city'
                  defaultValue={user.city}
                  data={actionData}
                  disabled={disableWhenLoading(navigation)}
                />
              </div>
              <div className='flex flex-col gap-x-2 md:flex-row md:space-x-2'>
                <FormGroup.Input
                  labelclassname='bg-gray-100'
                  className='flex-1'
                  type='text'
                  id='postal_code'
                  title='Postal code'
                  placeholder='Enter the postal code'
                  defaultValue={user.postalCode}
                  data={actionData}
                  disabled={disableWhenLoading(navigation)}
                />
                <FormGroup.Input
                  labelclassname='bg-gray-100'
                  className='flex-1'
                  type='text'
                  id='telephone'
                  title='Telephone'
                  placeholder='e.g +35811200019'
                  defaultValue={user.telephone}
                  data={actionData}
                  disabled={disableWhenLoading(navigation)}
                />
              </div>
              <div className='flex flex-col gap-x-2 md:flex-row md:space-x-2'>
                <FormGroup.Select
                  labelclassname='bg-gray-100'
                  className='flex-1'
                  id='country_of_origin'
                  title='Country of origin'
                  defaultValue={user.countryOfOrigin}
                  data={actionData}
                  disabled={disableWhenLoading(navigation)}
                >
                  {COUNTRIES.map((country) => (
                    <option value={country.value} key={country.value}>
                      {country.value}
                    </option>
                  ))}
                </FormGroup.Select>
                <FormGroup.Select
                  labelclassname='bg-gray-100'
                  className='flex-1'
                  id='country_of_residence'
                  title='Country of residence'
                  defaultValue={user.countryOfResidence}
                  data={actionData}
                  disabled={disableWhenLoading(navigation)}
                >
                  {COUNTRIES.map((country) => (
                    <option value={country.value} key={country.value}>
                      {country.value}
                    </option>
                  ))}
                </FormGroup.Select>
              </div>
              <div className='flex flex-col gap-x-2 md:flex-row md:space-x-2'>
                <FormGroup.Select
                  labelclassname='bg-gray-100'
                  className='flex-1'
                  id='role'
                  title='Role'
                  defaultValue={user.role}
                  data={actionData}
                  disabled={disableWhenLoading(navigation)}
                >
                  {roles.map((role) => (
                    <option
                      value={role.value}
                      key={role.value}
                      disabled={
                        role.value === ROLES.super && !isAuthorizedSuper()
                      }
                    >
                      {role.name}
                    </option>
                  ))}
                </FormGroup.Select>
                <FormGroup.Select
                  labelclassname='bg-gray-100'
                  className='flex-1'
                  id='gender'
                  title='Gender'
                  defaultValue={user.gender}
                  data={actionData}
                  disabled={disableWhenLoading(navigation)}
                >
                  {gender.map((gender) => (
                    <option value={gender.value} key={gender.value}>
                      {gender.name}
                    </option>
                  ))}
                </FormGroup.Select>
              </div>
              <div className='flex flex-col gap-x-2 md:flex-row md:space-x-2'>
                <FormGroup.Select
                  labelclassname='bg-gray-100'
                  className='flex-1'
                  id='permit_status'
                  title='Permit status'
                  defaultValue={user.permitStatus}
                  data={actionData}
                  disabled={disableWhenLoading(navigation)}
                >
                  {permitStatus.map((permit) => (
                    <option value={permit.value} key={permit.value}>
                      {permit.name}
                    </option>
                  ))}
                </FormGroup.Select>
                <FormGroup.Input
                  labelclassname='bg-gray-100'
                  className='flex-1'
                  type='email'
                  id='email'
                  title='Email'
                  defaultValue={user.email}
                  disabled
                  data={actionData}
                />
                <FormGroup.Select
                  labelclassname='bg-gray-100'
                  className='flex-1'
                  id='status'
                  title='Status'
                  defaultValue={user.status}
                  data={actionData}
                  disabled={disableWhenLoading(navigation)}
                >
                  {userStatus.map((status) => (
                    <option
                      value={status.value}
                      key={status.value}
                      disabled={status.value === USER_STATUS.pending}
                    >
                      {status.name}
                    </option>
                  ))}
                </FormGroup.Select>
              </div>
              <CButton
                rounded
                variant='primary'
                type='submit'
                disabled={disableButton(navigation)}
                className='mt-6'
              >
                {handleNavigationStatus(navigation, 'Submit')}
              </CButton>
            </FormGroup>
          </Form>
        </Card>
      )}
    </Container>
  );
}

export async function loader({ params }: LoaderFunctionArgs): Promise<LoaderFunction> {
  return await queryClient.fetchQuery({
    queryKey: ['users', params.id],
    queryFn: ({ signal }) =>
      getById({ signal, resource: `users/${params.id}` }),
  });
}

export async function action({ request, params }: ActionFunctionArgs): Promise<ActionFunction> {
  await queryClient.invalidateQueries({ queryKey: ['users'] });
  return await upsertResource({
    request,
    resource: `users/${params.id}`,
    isFormData: false,
    action: 'update',
  });
}
