import {
  CButton,
  FormGroup,
  disableButton,
  handleNavigationStatus,
} from '@maw/utility';
import {
  ActionFunction,
  ActionFunctionArgs,
  Form,
  LoaderFunction,
  LoaderFunctionArgs,
  useActionData,
  useNavigation,
  useParams,
} from 'react-router-dom';
import Card from '../../../Card';
import { getById, queryClient, upsertResource } from '../../../../util/request';
import Container from '../../../Container';
import Alert from '../../../Alert';
import { ENV, postStatus } from '../../../../util/constant';
import { useRedirect } from '../../../../hooks/redirect';
import { useQuery } from '@tanstack/react-query';
import HiddenInput from '../../../HiddenInput';
import {
  disableWhenLoading,
  getBlogCategoriesForSelectInput,
} from '../../../../util/helper';
import BlogCard from '../../../BlogCard';
import useOperation from '../../../../hooks/operation';
import Modal from '../../../Modal';
import ErrorAlert from '../../../ErrorAlert';

export default function BlogPostDetails() {
  const navigation = useNavigation();
  const actionData: any = useActionData();
  const params = useParams();
  const { data, isLoading } = useQuery({
    queryKey: ['blog-posts', params.slug],
    queryFn: ({ signal }) =>
      getById({ signal, resource: `blog-posts/${params.slug}` }),
  });

  const blog: BlogPost<'translations'> = data?.data.blogPost;
  const blogCategories = getBlogCategoriesForSelectInput(
    data?.data.blogCategories
  );

  const { id, show, handleDelete, markObjectForDeletion, closeModal } =
    useOperation();

  useRedirect(actionData, 'blog-posts', true);

  return (
    <>
      {!isLoading && (
        <Container>
          <ErrorAlert data={actionData} />
          <Modal
            show={show}
            onClose={closeModal}
            onAction={() =>
              handleDelete(`blog-posts/${id}/images/delete`, ['blog-posts', id])
            }
          />
          <Alert data={actionData} />
          <Container.Heading>Blog Details</Container.Heading>
          <Container.SubHeading>
            Authorized user can write the file.
          </Container.SubHeading>
          {blog && blog.coverImage && (
            <BlogCard
              className='my-4'
              id={blog.id}
              translation={blog.translations[0]}
              onDelete={() => markObjectForDeletion(blog.translations[0].slug)}
              showCard='delete'
              key={blog.id}
              author={blog.author}
              coverImage={blog.coverImage}
            />
          )}
          {blog && blogCategories && (
            <Card>
              <Card.Header>{blog.translations[0].title}</Card.Header>
              <Form method='post' encType='multipart/form-data' noValidate>
                <FormGroup>
                  <FormGroup.Select
                    labelclassname='bg-gray-100'
                    id='blog_category_id'
                    title='Blog category'
                    data={actionData}
                    defaultValue={blog.blogCategoryId}
                    disabled={disableWhenLoading(navigation)}
                  >
                    {blogCategories.map((blogCategory) => (
                      <option value={blogCategory.id} key={blogCategory.id}>
                        {blogCategory.name}
                      </option>
                    ))}
                  </FormGroup.Select>
                  <FormGroup.Select
                    labelclassname='bg-gray-100'
                    id='translations[0][post_status]'
                    title='Post status'
                    defaultValue={blog.translations[0].postStatus}
                    data={actionData}
                    disabled={disableWhenLoading(navigation)}
                  >
                    {postStatus.map((post) => (
                      <option value={post.value} key={post.value}>
                        {post.name}
                      </option>
                    ))}
                  </FormGroup.Select>
                  <FormGroup.Input
                    labelclassname='bg-gray-100'
                    className='flex-1'
                    type='file'
                    id='cover_image'
                    title='Cover image (MaxHeight: 500, MaxWidth: 800)'
                    accept='.jpeg, .png, .jpg, .svg'
                    data={actionData}
                    disabled={disableWhenLoading(navigation)}
                  />
                  <div className='mb-4'>
                    <label htmlFor='translations[0][is_comment_allowed]'>
                      Allow comments
                    </label>
                    <input
                      className='ml-2'
                      type='checkbox'
                      id='translations[0][is_comment_allowed]'
                      name='translations[0][is_comment_allowed]'
                      value='yes'
                      disabled={disableWhenLoading(navigation)}
                      defaultChecked={
                        blog.translations[0].isCommentAllowed === 'yes'
                      }
                    />
                  </div>
                  <div className='flex flex-col gap-x-2 md:flex-row md:space-x-2'>
                    <div className='flex-1'>
                      <p className='mb-8 text-gray-500 font-bold'>English</p>
                      <FormGroup.Input
                        labelclassname='bg-gray-100'
                        type='text'
                        id='translations[0][title][en]'
                        title='Title'
                        placeholder='Enter blog title'
                        defaultValue={blog.translations[0].title}
                        data={actionData}
                        disabled={disableWhenLoading(navigation)}
                      />
                      <FormGroup.Editor
                        labelclassname='bg-gray-100'
                        apiKey={ENV.editorApiKey}
                        id='translations[0][content][en]'
                        initialValue={blog.translations[0].content}
                        data={actionData}
                        disabled={disableWhenLoading(navigation)}
                      />
                    </div>
                    <div className='flex-1'>
                      <p className='mb-8 text-gray-500 font-bold'>Finnish</p>
                      <FormGroup.Input
                        labelclassname='bg-gray-100'
                        type='text'
                        id='translations[0][title][fi]'
                        title='Title'
                        placeholder='Enter blog title'
                        data={actionData}
                        defaultValue={blog.translations[1].title}
                        disabled={disableWhenLoading(navigation)}
                      />
                      <FormGroup.Editor
                        labelclassname='bg-gray-100'
                        apiKey={ENV.editorApiKey}
                        id='translations[0][content][fi]'
                        data={actionData}
                        initialValue={blog.translations[1].content}
                        disabled={disableWhenLoading(navigation)}
                      />
                      <HiddenInput />
                      <HiddenInput id='user_id' value={blog.author.id} />
                    </div>
                  </div>
                  <CButton
                    rounded
                    variant='primary'
                    type='submit'
                    disabled={disableButton(navigation)}
                    className='mt-6'
                  >
                    {handleNavigationStatus(navigation, 'Submit')}
                  </CButton>
                </FormGroup>
              </Form>
            </Card>
          )}
        </Container>
      )}
    </>
  );
}

export async function loader({
  params,
}: LoaderFunctionArgs): Promise<LoaderFunction> {
  return await queryClient.fetchQuery({
    queryKey: ['blog-posts', params.slug],
    queryFn: ({ signal }) =>
      getById({ signal, resource: `blog-posts/${params.slug}` }),
  });
}

export async function action({
  request,
  params,
}: ActionFunctionArgs): Promise<ActionFunction> {
  await queryClient.invalidateQueries({ queryKey: ['blog-posts'] });
  return await upsertResource({
    request,
    resource: `blog-posts/${params.slug}`,
    isFormData: true,
    action: 'update',
  });
}
