import {
  CButton,
  COUNTRIES,
  FormGroup,
  disableButton,
  handleNavigationStatus,
} from '@maw/utility';
import {
  ActionFunction,
  ActionFunctionArgs,
  Form,
  useActionData,
  useNavigation,
} from 'react-router-dom';
import Card from '../../../Card';
import { queryClient, upsertResource } from '../../../../util/request';
import Container from '../../../Container';
import { useRedirect } from '../../../../hooks/redirect';
import Alert from '../../../Alert';
import { disableWhenLoading } from '../../../../util/helper';

export default function NewCustomer() {
  const actionData: any = useActionData();
  const navigation = useNavigation();

  useRedirect(actionData, 'customers', true);

  return (
    <Container>
      <Alert data={actionData} />
      <Container.Heading>New Customer</Container.Heading>
      <Container.SubHeading>
        Authorized user can write the file.
      </Container.SubHeading>
      <Card>
        <Card.Header>New customer</Card.Header>
        <Form method='post' encType='multipart/form-data' noValidate>
          <FormGroup>
            <div className='flex flex-col gap-x-2 md:flex-row md:space-x-2'>
              <FormGroup.Input
                labelclassname='bg-gray-100'
                className='flex-1'
                type='text'
                id='contact_person'
                title='Contact person'
                placeholder='Enter name'
                data={actionData}
                disabled={disableWhenLoading(navigation)}
              />
              <FormGroup.Input
                labelclassname='bg-gray-100'
                className='flex-1'
                type='text'
                id='company_name'
                title='Company name'
                placeholder='Enter company name'
                data={actionData}
                disabled={disableWhenLoading(navigation)}
              />
            </div>
            <div className='flex flex-col gap-x-2 md:flex-row md:space-x-2'>
              <FormGroup.Input
                labelclassname='bg-gray-100'
                className='flex-1'
                type='email'
                id='email'
                title='Email'
                placeholder='Enter email'
                data={actionData}
                disabled={disableWhenLoading(navigation)}
              />
              <FormGroup.Input
                labelclassname='bg-gray-100'
                className='flex-1'
                type='phone'
                id='telephone'
                title='Telephone'
                placeholder='Enter telephone'
                data={actionData}
                disabled={disableWhenLoading(navigation)}
              />
            </div>
            <div className='flex flex-col gap-x-2 md:flex-row md:space-x-2'>
              <FormGroup.Input
                labelclassname='bg-gray-100'
                className='flex-1'
                type='text'
                id='address'
                title='Address'
                placeholder='Enter address'
                data={actionData}
                disabled={disableWhenLoading(navigation)}
              />
              <FormGroup.Input
                labelclassname='bg-gray-100'
                className='flex-1'
                type='text'
                id='city'
                title='City'
                placeholder='Enter city'
                data={actionData}
                disabled={disableWhenLoading(navigation)}
              />
            </div>
            <div className='flex flex-col gap-x-2 md:flex-row md:space-x-2'>
              <FormGroup.Input
                labelclassname='bg-gray-100'
                className='flex-1'
                type='file'
                id='logo'
                title='Logo (MaxHeight: 500, MaxWidth: 500)'
                accept='.jpeg, .png, .jpg, .svg'
                data={actionData}
                disabled={disableWhenLoading(navigation)}
              />
              <FormGroup.Input
                labelclassname='bg-gray-100'
                className='flex-1'
                type='text'
                id='postal_code'
                title='Postal code'
                placeholder='Enter postal code'
                data={actionData}
                disabled={disableWhenLoading(navigation)}
              />
            </div>
            <div className='flex flex-col gap-x-2 md:flex-row md:space-x-2'>
              <FormGroup.Input
                labelclassname='bg-gray-100'
                className='flex-1'
                type='text'
                id='business_number'
                title='Business'
                placeholder='Enter business number'
                data={actionData}
                disabled={disableWhenLoading(navigation)}
              />
              <FormGroup.Select
                labelclassname='bg-gray-100'
                className='flex-1'
                id='country_of_operation'
                title='Country of operation'
                data={actionData}
                disabled={disableWhenLoading(navigation)}
              >
                {COUNTRIES.map((country) => (
                  <option value={country.value} key={country.value}>
                    {country.value}
                  </option>
                ))}
              </FormGroup.Select>
            </div>
            <CButton
              rounded
              variant='primary'
              type='submit'
              disabled={disableButton(navigation)}
              className='mt-6'
            >
              {handleNavigationStatus(navigation, 'Submit')}
            </CButton>
          </FormGroup>
        </Form>
      </Card>
    </Container>
  );
}

export async function action({
  request,
}: ActionFunctionArgs): Promise<ActionFunction> {
  await queryClient.invalidateQueries({ queryKey: ['customers'] });
  return await upsertResource({
    request,
    resource: 'customers',
    isFormData: true,
    action: 'create',
  });
}
