import { PencilIcon, TrashIcon } from '@heroicons/react/20/solid';
import { ENV } from '../util/constant';
import placeholderImage from '../assets/placeholder-image.png';
import IconButton from './IconButton';
import { useNavigate } from 'react-router-dom';
import { FaCopy } from 'react-icons/fa6';
import { useCallback, useState } from 'react';

type AssetCardProps = {
  allowDelete?: boolean;
  allowView?: boolean;
  onDelete?: () => void;
  className?: string;
};

export default function AssetCard({
  id,
  url,
  allowDelete,
  allowView,
  onDelete,
  className,
}: Asset & AssetCardProps) {
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const navigate = useNavigate();

  const copyImageUrlToClipboard = useCallback(() => {
    const imageUrl = !url ? placeholderImage : `${ENV.assetBaseUrl}/${url}`;
    navigator.clipboard
      .writeText(imageUrl)
      .then(() => {
        setShowAlert(true);
        setTimeout(() => {
          setShowAlert(false);
        }, 3000);
      })
      .catch((err) => {
        alert(`Failed to copy image URL: ${err}`);
      });
  }, [url]);

  return (
    <figure
      className={`${
        className && className
      } relative w-full md:w-96 h-80 rounded-lg hover:shadow-lg overflow-hidden`}
      style={{
        backgroundImage: `url(${
          !url ? placeholderImage : `${ENV.assetBaseUrl}/${url}`
        })`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundColor: 'gray',
        backgroundRepeat: 'no-repeat',
        backgroundBlendMode: 'overlay',
      }}
    >
      {showAlert && (
        <div className='absolute left-2 top-2 inline-block p-2 bg-slate-400 text-white text-sm rounded-sm'>
          Copied
        </div>
      )}
      <div className='flex absolute right-4 top-2 gap-x-2'>
        <IconButton
          onClick={copyImageUrlToClipboard}
          icon={
            <FaCopy
              className='bg-gray-500 bg-opacity-50 rounded-full h-8 w-8 p-1 text-yellow-500 hover:text-yellow-600 hover:bg-white transition-colors'
              aria-hidden='true'
            />
          }
        />
        {allowView && url && (
          <IconButton
            onClick={() => navigate(`/dashboard/assets/${id}`)}
            icon={
              <PencilIcon
                className='bg-gray-500 bg-opacity-50 rounded-full h-8 w-8 p-1 text-indigo-500 hover:text-indigo-600 hover:bg-white transition-colors'
                aria-hidden='true'
              />
            }
          />
        )}
        {allowDelete && url && (
          <IconButton
            onClick={onDelete}
            icon={
              <TrashIcon
                className='bg-gray-500 bg-opacity-50 rounded-full h-8 w-8 p-1 text-red-500 hover:text-red-600 hover:bg-white transition-colors'
                aria-hidden='true'
              />
            }
          />
        )}
      </div>
    </figure>
  );
}
