import Container from '../../../Container';
import { getResources, queryClient } from '../../../../util/request';
import Modal from '../../../Modal';
import useOperation from '../../../../hooks/operation';
import { useQuery } from '@tanstack/react-query';
import TrustBadge from '../../../TrustBadge';
import { LoaderFunction } from 'react-router-dom';

export default function TrustBadges() {
  const { data, isLoading } = useQuery({
    queryKey: ['trust-badges'],
    queryFn: ({ signal }) => getResources({ signal, url: 'trust-badges' }),
  });
  const trustBadges: TrustBadge[] = data?.data;
  const { id, show, handleDelete, markObjectForDeletion, closeModal } =
    useOperation();

  return (
    <>
      {!isLoading && (
        <Container>
          <Modal
            show={show}
            onClose={closeModal}
            onAction={() =>
              handleDelete(`trust-badges/${id}`, ['trust-badges'])
            }
          />
          {trustBadges.length ? (
            <>
              <Container.Heading>Trust badges</Container.Heading>
              <Container.SubHeading>
                List of all trust badges.
              </Container.SubHeading>
              <div className='flex gap-4 flex-wrap'>
                {trustBadges.map((badge) => (
                  <TrustBadge
                    key={badge.id}
                    postStatus={badge.postStatus}
                    name={badge.name}
                    id={badge.id}
                    image={badge.image}
                    onDelete={() => markObjectForDeletion(badge.id)}
                  />
                ))}
              </div>
            </>
          ) : (
            <Container.Heading>No data</Container.Heading>
          )}
        </Container>
      )}
    </>
  );
}

export async function loader(): Promise<LoaderFunction> {
  return await queryClient.fetchQuery({
    queryKey: ['trust-badges'],
    queryFn: ({ signal }) => getResources({ signal, url: 'trust-badges' }),
  });
}
