import {
  CButton,
  FormGroup,
  disableButton,
  handleNavigationStatus,
} from '@maw/utility';
import {
  ActionFunction,
  ActionFunctionArgs,
  Form,
  LoaderFunction,
  LoaderFunctionArgs,
  useActionData,
  useNavigation,
  useParams,
} from 'react-router-dom';
import Card from '../../../Card';
import { getById, queryClient, upsertResource } from '../../../../util/request';
import Container from '../../../Container';
import Alert from '../../../Alert';
import { useRedirect } from '../../../../hooks/redirect';
import {
  contactDepartment,
  gender,
  postStatus,
} from '../../../../util/constant';
import { Contact } from '../../../../types/contact';
import { useQuery } from '@tanstack/react-query';
import HiddenInput from '../../../HiddenInput';
import Modal from '../../../Modal';
import useOperation from '../../../../hooks/operation';
import ContactCard from '../../../ContactCard';
import { disableWhenLoading } from '../../../../util/helper';
import ErrorAlert from '../../../ErrorAlert';

export default function ContactDetails() {
  const navigation = useNavigation();
  const params = useParams();
  const { id, show, handleDelete, markObjectForDeletion, closeModal } =
    useOperation();
  const { data } = useQuery({
    queryKey: ['contacts', params.id],
    queryFn: ({ signal }) =>
      getById({ signal, resource: `contacts/${params.id}` }),
  });

  const actionData: any = useActionData();
  useRedirect(actionData, 'contacts', true);
  const contact: Contact = data?.data;

  return (
    <Container>
      <ErrorAlert data={actionData} />
      <Modal
        show={show}
        onClose={closeModal}
        onAction={() =>
          handleDelete(`contacts/${id}/images/delete`, ['contacts', id])
        }
      />
      <Alert data={actionData} />
      <Container.Heading>Contact Details</Container.Heading>
      <Container.SubHeading>
        Authorized user can write the file.
      </Container.SubHeading>
      {contact && contact.avatar && (
        <ContactCard
          className='my-4'
          translations={contact.translations}
          telephone={contact.telephone}
          email={contact.email}
          firstName={contact.firstName}
          lastName={contact.lastName}
          id={contact.id}
          onDelete={() => markObjectForDeletion(contact.id)}
          showCard='delete'
          key={contact.id}
          avatar={contact.avatar}
        />
      )}
      {contact && (
        <Card>
          <Card.Header>Contact Details</Card.Header>
          {contact && (
            <Form method='post' encType='multipart/form-data' noValidate>
              <FormGroup>
                <div className='flex flex-col gap-x-2 md:flex-row md:space-x-2'>
                  <FormGroup.Input
                    labelclassname='bg-gray-100'
                    className='flex-1'
                    type='text'
                    id='first_name'
                    title='First name'
                    placeholder='Enter first name'
                    defaultValue={contact.firstName}
                    data={actionData}
                    disabled={disableWhenLoading(navigation)}
                  />
                  <FormGroup.Input
                    labelclassname='bg-gray-100'
                    className='flex-1'
                    type='text'
                    id='last_name'
                    title='Last name'
                    placeholder='Enter last name'
                    defaultValue={contact.lastName}
                    data={actionData}
                    disabled={disableWhenLoading(navigation)}
                  />
                </div>
                <div className='flex flex-col gap-x-2 md:flex-row md:space-x-2'>
                  <FormGroup.Input
                    labelclassname='bg-gray-100'
                    className='flex-1'
                    type='email'
                    id='email'
                    title='Email'
                    placeholder='Enter email'
                    defaultValue={contact.email}
                    data={actionData}
                    disabled={disableWhenLoading(navigation)}
                  />
                  <FormGroup.Input
                    labelclassname='bg-gray-100'
                    className='flex-1'
                    type='phone'
                    id='telephone'
                    title='Telephone'
                    placeholder='Enter telephone'
                    defaultValue={contact.telephone}
                    data={actionData}
                    disabled={disableWhenLoading(navigation)}
                  />
                </div>
                <div className='flex flex-col gap-x-2 md:flex-row md:space-x-2'>
                  <FormGroup.Input
                    labelclassname='bg-gray-100'
                    className='flex-1'
                    type='text'
                    id='address'
                    title='Address'
                    placeholder='Enter address'
                    defaultValue={contact.address}
                    data={actionData}
                    disabled={disableWhenLoading(navigation)}
                  />
                  <FormGroup.Input
                    labelclassname='bg-gray-100'
                    className='flex-1'
                    type='text'
                    id='city'
                    title='City'
                    placeholder='Enter city'
                    defaultValue={contact.city}
                    data={actionData}
                    disabled={disableWhenLoading(navigation)}
                  />
                </div>
                <div className='flex flex-col gap-x-2 md:flex-row md:space-x-2'>
                  <FormGroup.Input
                    labelclassname='bg-gray-100'
                    className='flex-1'
                    type='file'
                    id='avatar'
                    title='Avatar (MaxHeight: 500, MaxWidth: 500'
                    accept='.jpeg, .png, .jpg, .svg'
                    data={actionData}
                    disabled={disableWhenLoading(navigation)}
                  />
                  <FormGroup.Input
                    labelclassname='bg-gray-100'
                    className='flex-1'
                    type='text'
                    id='postal_code'
                    title='Postal code'
                    placeholder='Enter postal code'
                    defaultValue={contact.postalCode}
                    data={actionData}
                    disabled={disableWhenLoading(navigation)}
                  />
                </div>
                <div className='flex flex-col gap-x-2 md:flex-row md:space-x-2'>
                  <FormGroup.Select
                    labelclassname='bg-gray-100'
                    className='flex-1'
                    id='translations[0][department]'
                    title='Department'
                    defaultValue={contact.translations[0].department}
                    data={actionData}
                    disabled={disableWhenLoading(navigation)}
                  >
                    {contactDepartment.map((department) => (
                      <option value={department.value} key={department.value}>
                        {department.name}
                      </option>
                    ))}
                  </FormGroup.Select>
                  <FormGroup.Select
                    labelclassname='bg-gray-100'
                    className='flex-1'
                    id='translations[0][gender]'
                    title='Gender'
                    defaultValue={contact.translations[0].gender}
                    data={actionData}
                    disabled={disableWhenLoading(navigation)}
                  >
                    {gender.map((element) => (
                      <option value={element.value} key={element.value}>
                        {element.name}
                      </option>
                    ))}
                  </FormGroup.Select>
                </div>
                <div className='flex flex-col gap-x-2 md:flex-row md:space-x-2'>
                  <FormGroup.Select
                    labelclassname='bg-gray-100'
                    className='flex-1'
                    id='translations[0][post_status]'
                    title='Post status'
                    defaultValue={contact.translations[0].postStatus}
                    data={actionData}
                    disabled={disableWhenLoading(navigation)}
                  >
                    {postStatus.map((status) => (
                      <option value={status.value} key={status.value}>
                        {status.name}
                      </option>
                    ))}
                  </FormGroup.Select>
                </div>
                <HiddenInput />
                <CButton
                  rounded
                  variant='primary'
                  type='submit'
                  disabled={disableButton(navigation)}
                  className='mt-6'
                >
                  {handleNavigationStatus(navigation, 'Submit')}
                </CButton>
              </FormGroup>
            </Form>
          )}
        </Card>
      )}
    </Container>
  );
}

export async function loader({
  params,
}: LoaderFunctionArgs): Promise<LoaderFunction> {
  return await queryClient.fetchQuery({
    queryKey: ['contacts', params.id],
    queryFn: ({ signal }) =>
      getById({ signal, resource: `contacts/${params.id}` }),
  });
}

export async function action({
  request,
  params,
}: ActionFunctionArgs): Promise<ActionFunction> {
  await queryClient.invalidateQueries({ queryKey: ['contacts'] });
  return await upsertResource({
    request,
    resource: `contacts/${params.id}`,
    isFormData: true,
    action: 'update',
  });
}
