import {
  CButton,
  COUNTRIES,
  FormGroup,
  disableButton,
  handleNavigationStatus,
} from '@maw/utility';
import {
  ActionFunction,
  ActionFunctionArgs,
  Form,
  LoaderFunction,
  LoaderFunctionArgs,
  useActionData,
  useNavigation,
  useParams,
} from 'react-router-dom';
import Card from '../../../Card';
import { getById, queryClient, upsertResource } from '../../../../util/request';
import Container from '../../../Container';
import Alert from '../../../Alert';
import {
  employmentStatus,
  gender,
  permitStatus,
} from '../../../../util/constant';
import { disableWhenLoading } from '../../../../util/helper';
import { useRedirect } from '../../../../hooks/redirect';
import { useQuery } from '@tanstack/react-query';
import { Employee } from '../../../../types/employee';
import HiddenInput from '../../../HiddenInput';

export default function EmployeeDetails() {
  const navigation = useNavigation();
  const params = useParams();
  const actionData: any = useActionData();
  const { data } = useQuery({
    queryKey: ['employees', params.id],
    queryFn: ({ signal }) =>
      getById({ signal, resource: `employees/${params.id}` }),
  });
  const employee: Employee = data?.data;

  useRedirect(actionData, 'employees', true);

  return (
    <Container>
      <Alert data={actionData} />
      <Container.Heading>Employee Details</Container.Heading>
      <Container.SubHeading>
        Authorized user can write the file.
      </Container.SubHeading>
      {employee && (
        <Card>
          <Card.Header>{`${employee.firstName} ${employee.lastName}`}</Card.Header>
          <Form method='post' encType='multipart/form-data' noValidate>
            <FormGroup>
              <div className='flex flex-col gap-x-2 md:flex-row md:space-x-2'>
                <FormGroup.Input
                  labelclassname='bg-gray-100'
                  className='flex-1'
                  type='text'
                  id='first_name'
                  title='First name'
                  placeholder='Enter first name'
                  defaultValue={employee.firstName}
                  data={actionData}
                  disabled={disableWhenLoading(navigation)}
                />
                <FormGroup.Input
                  labelclassname='bg-gray-100'
                  className='flex-1'
                  type='text'
                  id='last_name'
                  title='Last name'
                  placeholder='Enter last name'
                  defaultValue={employee.lastName}
                  data={actionData}
                  disabled={disableWhenLoading(navigation)}
                />
              </div>
              <div className='flex flex-col gap-x-2 md:flex-row md:space-x-2'>
                <FormGroup.Input
                  labelclassname='bg-gray-100'
                  className='flex-1'
                  type='text'
                  id='address'
                  title='Address'
                  placeholder='Enter your address'
                  defaultValue={employee.address}
                  data={actionData}
                  disabled={disableWhenLoading(navigation)}
                />
                <FormGroup.Input
                  labelclassname='bg-gray-100'
                  className='flex-1'
                  type='text'
                  id='city'
                  title='City'
                  placeholder='Enter your city'
                  defaultValue={employee.city}
                  data={actionData}
                  disabled={disableWhenLoading(navigation)}
                />
              </div>
              <div className='flex flex-col gap-x-2 md:flex-row md:space-x-2'>
                <FormGroup.Input
                  labelclassname='bg-gray-100'
                  className='flex-1'
                  type='text'
                  id='postal_code'
                  title='Postal code'
                  placeholder='Enter the postal code'
                  defaultValue={employee.postalCode}
                  data={actionData}
                  disabled={disableWhenLoading(navigation)}
                />
                <FormGroup.Input
                  labelclassname='bg-gray-100'
                  className='flex-1'
                  type='text'
                  id='telephone'
                  title='Telephone'
                  placeholder='e.g +35811200019'
                  defaultValue={employee.telephone}
                  data={actionData}
                  disabled={disableWhenLoading(navigation)}
                />
              </div>
              <div className='flex flex-col gap-x-2 md:flex-row md:space-x-2'>
                <FormGroup.Select
                  labelclassname='bg-gray-100'
                  className='flex-1'
                  id='country_of_origin'
                  title='Country of origin'
                  defaultValue={employee.countryOfOrigin}
                  data={actionData}
                  disabled={disableWhenLoading(navigation)}
                >
                  {COUNTRIES.map((country) => (
                    <option value={country.value} key={country.value}>
                      {country.value}
                    </option>
                  ))}
                </FormGroup.Select>
                <FormGroup.Select
                  labelclassname='bg-gray-100'
                  className='flex-1'
                  id='country_of_residence'
                  title='Country of residence'
                  defaultValue={employee.countryOfResidence}
                  data={actionData}
                  disabled={disableWhenLoading(navigation)}
                >
                  {COUNTRIES.map((country) => (
                    <option value={country.value} key={country.value}>
                      {country.value}
                    </option>
                  ))}
                </FormGroup.Select>
              </div>
              <div className='flex flex-col gap-x-2 md:flex-row md:space-x-2'>
                <FormGroup.Select
                  labelclassname='bg-gray-100'
                  className='flex-1'
                  id='employment_status'
                  title='Employment status'
                  defaultValue={employee.employmentStatus}
                  data={actionData}
                  disabled={disableWhenLoading(navigation)}
                >
                  {employmentStatus.map((status) => (
                    <option value={status.value} key={status.value}>
                      {status.name}
                    </option>
                  ))}
                </FormGroup.Select>
                <FormGroup.Select
                  labelclassname='bg-gray-100'
                  className='flex-1'
                  id='gender'
                  title='Gender'
                  defaultValue={employee.gender}
                  data={actionData}
                  disabled={disableWhenLoading(navigation)}
                >
                  {gender.map((gender) => (
                    <option value={gender.value} key={gender.value}>
                      {gender.name}
                    </option>
                  ))}
                </FormGroup.Select>
              </div>
              <div className='flex flex-col gap-x-2 md:flex-row md:space-x-2'>
                <FormGroup.Select
                  labelclassname='bg-gray-100'
                  className='flex-1'
                  id='permit_status'
                  title='Permit status'
                  defaultValue={employee.permitStatus}
                  data={actionData}
                  disabled={disableWhenLoading(navigation)}
                >
                  {permitStatus.map((permit) => (
                    <option value={permit.value} key={permit.value}>
                      {permit.name}
                    </option>
                  ))}
                </FormGroup.Select>
                <FormGroup.Input
                  labelclassname='bg-gray-100'
                  className='flex-1'
                  type='email'
                  id='email'
                  title='Email'
                  defaultValue={employee.email}
                  disabled
                  data={actionData}
                />
              </div>
              <FormGroup.Input
                labelclassname='bg-gray-100'
                type='file'
                id='avatar'
                title='Avatar (MaxHeight: 500, MaxWidth: 500)'
                accept='.jpeg, .png, .jpg, .svg'
                data={actionData}
                disabled={disableWhenLoading(navigation)}
              />
              <HiddenInput />
              <CButton
                rounded
                variant='primary'
                type='submit'
                disabled={disableButton(navigation)}
                className='mt-6'
              >
                {handleNavigationStatus(navigation, 'Submit')}
              </CButton>
            </FormGroup>
          </Form>
        </Card>
      )}
    </Container>
  );
}

export async function loader({
  params,
}: LoaderFunctionArgs): Promise<LoaderFunction> {
  return await queryClient.fetchQuery({
    queryKey: ['employees', params.id],
    queryFn: ({ signal }) =>
      getById({ signal, resource: `employees/${params.id}` }),
  });
}

export async function action({
  request,
  params,
}: ActionFunctionArgs): Promise<ActionFunction> {
  await queryClient.invalidateQueries({ queryKey: ['employees'] });
  return await upsertResource({
    request,
    resource: `employees/${params.id}`,
    isFormData: true,
    action: 'update',
  });
}
