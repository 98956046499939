import { EmploymentStatus } from '../types/employee';
import { UserStatus } from '../types/user';
import { handleBadgeColor } from '../util/helper';

type BadgeProps = {
  children: React.ReactNode;
  status: PostStatus | UserStatus | EmploymentStatus;
};

export default function Badge({ children, status }: BadgeProps) {
  return (
    <div
      className={`rounded-[1.2rem] p-1 inline-block text-xs text-center ${
        status ? handleBadgeColor(status) : ''
      }`}
    >
      {children}
    </div>
  );
}
