var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { forwardRef } from 'react';
import { ErrorManager } from '../../util/error-manager';
import { INPUTSTYLES } from '../../util/constants/status';
var Input = forwardRef(function (_a, ref) {
    var id = _a.id, data = _a.data, className = _a.className, title = _a.title, labelclassname = _a.labelclassname, disabled = _a.disabled, isRequired = _a.isRequired, rest = __rest(_a, ["id", "data", "className", "title", "labelclassname", "disabled", "isRequired"]);
    var errorInstance = new ErrorManager(false);
    errorInstance.hasError(data, id);
    return (_jsxs("div", __assign({ className: "".concat(className && className, " ").concat(INPUTSTYLES.parent) }, { children: [_jsx("label", __assign({ htmlFor: id, className: "".concat(INPUTSTYLES.label, " ").concat(labelclassname && labelclassname) }, { children: _jsxs("div", __assign({ className: 'flex' }, { children: [_jsx("p", __assign({ className: 'inline-block' }, { children: title })), isRequired && _jsx("p", __assign({ className: 'inline-block ml-1 text-red-400' }, { children: "*" }))] })) })), _jsx("input", __assign({ id: id, name: id, ref: ref, disabled: disabled, className: "".concat(INPUTSTYLES.child, " ").concat(disabled ? 'bg-gray-200 cursor-not-allowed' : '', " ").concat(errorInstance.getErrorStyle()) }, rest)), errorInstance.getErrorMessage(id)] })));
});
export default Input;
