import { ReactNode } from 'react';

interface IconButtonProps {
  icon: ReactNode;
  onClick?: () => void;
  className?: string;
}

export default function IconButton({
  icon,
  onClick,
  className,
}: IconButtonProps) {
  return (
    <button onClick={onClick} className={`${className && className}`}>
      {icon}
    </button>
  );
}
