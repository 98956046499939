import Container from '../../../Container';
import { getPaginatedData, queryClient } from '../../../../util/request';
import Modal from '../../../Modal';
import useOperation from '../../../../hooks/operation';
import { useState } from 'react';
import BlogCard from '../../../BlogCard';
import Pagination from '../../../Pagination';
import { useQuery } from '@tanstack/react-query';
import { LoaderFunction } from 'react-router-dom';

export default function BlogPosts() {
  const [currentPage, setCurrentPage] = useState<number>(1);

  const { data, isLoading } = useQuery({
    queryKey: ['blog-posts', currentPage],
    queryFn: ({ signal }) =>
      getPaginatedData({
        signal,
        currentPage,
        perPage: 20,
        resource: 'blog-posts/paginated/all',
      }),
  });

  const paginatedPosts = data?.data;

  const handlePageChange = (
    direction: 'prev' | 'next' | 'specific',
    pageNumber?: number
  ) => {
    if (direction === 'specific' && pageNumber) {
      setCurrentPage(pageNumber);
    } else if (direction === 'prev') {
      setCurrentPage(currentPage - 1);
    } else if (direction === 'next') {
      setCurrentPage(currentPage + 1);
    }
  };

  const { id, show, handleDelete, markObjectForDeletion, closeModal } =
    useOperation();

  return (
    <>
      {!isLoading && (
        <Container>
          <Modal
            show={show}
            onClose={closeModal}
            onAction={() =>
              handleDelete(`blog-posts/${id}`, ['blog-posts', currentPage])
            }
          />
          {paginatedPosts.data && paginatedPosts.data.length ? (
            <>
              <Container.Heading>Blog Posts</Container.Heading>
              <Container.SubHeading>
                List of all blog posts.
              </Container.SubHeading>
              <div className='flex gap-4 flex-wrap'>
                {paginatedPosts.data.map((post: BlogPost<'translation'>) => (
                  <BlogCard
                    id={post.id}
                    showCard='all'
                    translation={post.translation}
                    onDelete={() =>
                      markObjectForDeletion(post.translation.slug)
                    }
                    key={post.id}
                    author={post.author}
                    coverImage={post.coverImage}
                  />
                ))}
              </div>
              {paginatedPosts &&
                paginatedPosts.total > paginatedPosts.perPage && (
                  <Pagination
                    onHandlePageChange={handlePageChange}
                    total={paginatedPosts.total}
                    perPage={paginatedPosts.perPage}
                    currentPage={paginatedPosts.currentPage}
                  />
                )}
            </>
          ) : (
            <Container.Heading>No data</Container.Heading>
          )}
        </Container>
      )}
    </>
  );
}

export async function loader(): Promise<LoaderFunction> {
  const page = 1;
  return await queryClient.fetchQuery({
    queryKey: ['blog-posts'],
    queryFn: ({ signal }) =>
      getPaginatedData({
        signal,
        currentPage: page,
        perPage: 20,
        resource: 'blog-posts/paginated/all',
      }),
  });
}
