var Str = /** @class */ (function () {
    function Str() {
    }
    Str.limitWord = function (sentence, limit) {
        var result = [];
        if (sentence.length > 1 && limit > 1) {
            var splitSentence = sentence.split(/\s+/);
            for (var i = 0; i < limit; i++) {
                result.push(splitSentence[i]);
            }
        }
        else {
            throw new Error('The passed sentence and the limiter must be greater than one.');
        }
        return result.join(' ');
    };
    Str.capitalize = function (data) {
        if (typeof data !== 'string' || data.length < 1) {
            throw new Error('The expected argument must be string and cannot be empty.');
        }
        if (data.length === 1) {
            return data.charAt(0).toUpperCase();
        }
        return data.charAt(0).toUpperCase() + data.toLowerCase().slice(1);
    };
    Str.toLowerCase = function (data, options) {
        if (options === void 0) { options = 'default'; }
        if (typeof data !== 'string' || data.length < 1) {
            throw new Error('The expected argument must be string and cannot be empty.');
        }
        return options === 'locale' ? data.toLocaleLowerCase() : data.toLowerCase();
    };
    return Str;
}());
export { Str };
