type ContainerProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> & { children: React.ReactNode };

type HeadingProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLHeadingElement>,
  HTMLHeadingElement
> & { children: React.ReactNode };

function Heading({ children, ...rest }: HeadingProps) {
  return (
    <h1 className='text-xl text-gray-600 font-bold mb-4 uppercase' {...rest}>
      {children}
    </h1>
  );
}

function SubHeading({ children, ...rest }: HeadingProps) {
  return (
    <h2 className='text-sm text-gray-500 mb-4' {...rest}>
      {children}
    </h2>
  );
}

export default function Container({ children, ...rest }: ContainerProps) {
  return (
    <div {...rest} className='my-10 p-6 bg-gray-100 rounded-md'>
      {children}
    </div>
  );
}

Container.Heading = Heading;
Container.SubHeading = SubHeading;
