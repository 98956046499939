import {
  CButton,
  FormGroup,
  disableButton,
  handleNavigationStatus,
} from '@maw/utility';
import {
  ActionFunction,
  ActionFunctionArgs,
  Form,
  useActionData,
  useNavigation,
} from 'react-router-dom';
import Card from '../../../Card';
import { queryClient, upsertResource } from '../../../../util/request';
import Container from '../../../Container';
import { useRedirect } from '../../../../hooks/redirect';
import Alert from '../../../Alert';
import { contactDepartment, gender } from '../../../../util/constant';
import { disableWhenLoading } from '../../../../util/helper';

export default function NewContact() {
  const actionData: any = useActionData();
  const navigation = useNavigation();

  useRedirect(actionData, 'contacts', true);

  return (
    <Container>
      <Alert data={actionData} />
      <Container.Heading>New Contact</Container.Heading>
      <Container.SubHeading>
        Authorized user can write the file.
      </Container.SubHeading>
      <Card>
        <Card.Header>New contact</Card.Header>
        <Form method='post' encType='multipart/form-data' noValidate>
          <FormGroup>
            <div className='flex flex-col gap-x-2 md:flex-row md:space-x-2'>
              <FormGroup.Input
                labelclassname='bg-gray-100'
                className='flex-1'
                type='text'
                id='first_name'
                title='First name'
                placeholder='Enter first name'
                data={actionData}
                disabled={disableWhenLoading(navigation)}
              />
              <FormGroup.Input
                labelclassname='bg-gray-100'
                className='flex-1'
                type='text'
                id='last_name'
                title='Last name'
                placeholder='Enter last name'
                data={actionData}
                disabled={disableWhenLoading(navigation)}
              />
            </div>
            <div className='flex flex-col gap-x-2 md:flex-row md:space-x-2'>
              <FormGroup.Input
                labelclassname='bg-gray-100'
                className='flex-1'
                type='email'
                id='email'
                title='Email'
                placeholder='Enter email'
                data={actionData}
                disabled={disableWhenLoading(navigation)}
              />
              <FormGroup.Input
                labelclassname='bg-gray-100'
                className='flex-1'
                type='phone'
                id='telephone'
                title='Telephone'
                placeholder='Enter telephone'
                data={actionData}
                disabled={disableWhenLoading(navigation)}
              />
            </div>
            <div className='flex flex-col gap-x-2 md:flex-row md:space-x-2'>
              <FormGroup.Input
                labelclassname='bg-gray-100'
                className='flex-1'
                type='text'
                id='address'
                title='Address'
                placeholder='Enter address'
                data={actionData}
                disabled={disableWhenLoading(navigation)}
              />
              <FormGroup.Input
                labelclassname='bg-gray-100'
                className='flex-1'
                type='text'
                id='city'
                title='City'
                placeholder='Enter city'
                data={actionData}
                disabled={disableWhenLoading(navigation)}
              />
            </div>
            <div className='flex flex-col gap-x-2 md:flex-row md:space-x-2'>
              <FormGroup.Input
                labelclassname='bg-gray-100'
                className='flex-1'
                type='file'
                id='avatar'
                title='Avatar (MaxHeight: 500, MaxWidth: 500)'
                accept='.jpeg, .png, .jpg, .svg'
                data={actionData}
                disabled={disableWhenLoading(navigation)}
              />
              <FormGroup.Input
                labelclassname='bg-gray-100'
                className='flex-1'
                type='text'
                id='postal_code'
                title='Postal code'
                placeholder='Enter postal code'
                data={actionData}
                disabled={disableWhenLoading(navigation)}
              />
            </div>
            <div className='flex flex-col gap-x-2 md:flex-row md:space-x-2'>
              <FormGroup.Select
                labelclassname='bg-gray-100'
                className='flex-1'
                id='translations[0][department]'
                title='Department'
                data={actionData}
                disabled={disableWhenLoading(navigation)}
              >
                {contactDepartment.map((department) => (
                  <option value={department.value} key={department.value}>
                    {department.name}
                  </option>
                ))}
              </FormGroup.Select>
              <FormGroup.Select
                labelclassname='bg-gray-100'
                className='flex-1'
                id='translations[0][gender]'
                title='Gender'
                data={actionData}
                disabled={disableWhenLoading(navigation)}
              >
                {gender.map((element) => (
                  <option value={element.value} key={element.value}>
                    {element.name}
                  </option>
                ))}
              </FormGroup.Select>
            </div>
            <CButton
              rounded
              variant='primary'
              type='submit'
              disabled={disableButton(navigation)}
              className='mt-6'
            >
              {handleNavigationStatus(navigation, 'Submit')}
            </CButton>
          </FormGroup>
        </Form>
      </Card>
    </Container>
  );
}

export async function action({
  request,
}: ActionFunctionArgs): Promise<ActionFunction> {
  await queryClient.invalidateQueries({ queryKey: ['contacts'] });
  return await upsertResource({
    request,
    resource: 'contacts',
    isFormData: true,
    action: 'create',
  });
}
