import {
  CButton,
  FormGroup,
  disableButton,
  handleNavigationStatus,
} from '@maw/utility';
import {
  ActionFunction,
  ActionFunctionArgs,
  Form,
  LoaderFunction,
  LoaderFunctionArgs,
  useActionData,
  useNavigation,
  useParams,
} from 'react-router-dom';
import Card from '../../../Card';
import { getById, queryClient, upsertResource } from '../../../../util/request';
import Container from '../../../Container';
import Alert from '../../../Alert';
import { useRedirect } from '../../../../hooks/redirect';
import { ENV, postStatus, serviceCategories } from '../../../../util/constant';
import { useQuery } from '@tanstack/react-query';
import { disableWhenLoading } from '../../../../util/helper';
import HiddenInput from '../../../HiddenInput';
import ErrorAlert from '../../../ErrorAlert';

export default function ServiceDetails() {
  const navigation = useNavigation();
  const params = useParams();
  const { data } = useQuery({
    queryKey: ['services', params.id],
    queryFn: ({ signal }) =>
      getById({ signal, resource: `services/${params.id}` }),
  });
  const actionData: any = useActionData();

  useRedirect(actionData, 'services', true);

  const service: Service = data?.data;

  return (
    <Container>
      <ErrorAlert data={actionData} />
      <Alert data={actionData} />
      <Container.Heading>Service Details</Container.Heading>
      <Container.SubHeading>
        Authorized user can write the file.
      </Container.SubHeading>
      {service && (
        <Card>
          <Card.Header>Service Details</Card.Header>
          {service && (
            <Form method='post' encType='multipart/form-data' noValidate>
              <FormGroup>
                <div className='flex flex-col gap-x-2 md:flex-row md:space-x-2'>
                  <div className='flex-1'>
                    <p className='mb-8 text-gray-500 font-bold'>English</p>
                    <FormGroup.Input
                      labelclassname='bg-gray-100'
                      type='text'
                      id='translations[0][title][en]'
                      title='Title'
                      placeholder='Enter title'
                      defaultValue={service.translations[0].title}
                      data={actionData}
                      disabled={disableWhenLoading(navigation)}
                    />
                    <FormGroup.Input
                      labelclassname='bg-gray-100'
                      type='text'
                      id='translations[0][sub_title][en]'
                      title='Sub title'
                      placeholder='Enter sub title'
                      defaultValue={service.translations[0].subTitle}
                      data={actionData}
                      disabled={disableWhenLoading(navigation)}
                    />
                    <FormGroup.Editor
                      labelclassname='bg-gray-100'
                      apiKey={ENV.editorApiKey}
                      id='translations[0][description][en]'
                      placeholder='Enter description'
                      initialValue={service.translations[0].description}
                      data={actionData}
                      disabled={disableWhenLoading(navigation)}
                    />
                  </div>
                  <div className='flex-1'>
                    <p className='mb-8 text-gray-500 font-bold'>Finnish</p>
                    <FormGroup.Input
                      labelclassname='bg-gray-100'
                      type='text'
                      id='translations[0][title][fi]'
                      title='Title'
                      placeholder='Enter title'
                      defaultValue={service.translations[1].title}
                      data={actionData}
                      disabled={disableWhenLoading(navigation)}
                    />
                    <FormGroup.Input
                      labelclassname='bg-gray-100'
                      type='text'
                      id='translations[0][sub_title][fi]'
                      title='Sub title'
                      placeholder='Enter sub title'
                      defaultValue={service.translations[1].subTitle}
                      data={actionData}
                      disabled={disableWhenLoading(navigation)}
                    />
                    <FormGroup.Editor
                      labelclassname='bg-gray-100'
                      apiKey={ENV.editorApiKey}
                      id='translations[0][description][fi]'
                      placeholder='Enter description'
                      initialValue={service.translations[1].description}
                      data={actionData}
                      disabled={disableWhenLoading(navigation)}
                    />
                  </div>
                </div>
                <div className='flex-1'>
                  <p className='mb-8 text-gray-500 font-bold'>Common inputs</p>
                  <FormGroup.Input
                    labelclassname='bg-gray-100'
                    type='file'
                    id='cover_image'
                    title='Cover image (MaxHeight: 500, MaxWidth: 800)'
                    accept='.jpeg, .png, .jpg, .svg'
                    data={actionData}
                    disabled={disableWhenLoading(navigation)}
                  />
                  <FormGroup.Select
                    labelclassname='bg-gray-100'
                    className='flex-1'
                    id='translations[0][service_category]'
                    title='Service category'
                    defaultValue={service.translations[0].serviceCategory}
                    data={actionData}
                    disabled={disableWhenLoading(navigation)}
                  >
                    {serviceCategories.map((category) => (
                      <option value={category.value} key={category.value}>
                        {category.name}
                      </option>
                    ))}
                  </FormGroup.Select>
                  <FormGroup.Select
                    labelclassname='bg-gray-100'
                    className='flex-1'
                    id='translations[0][post_status]'
                    title='Post status'
                    defaultValue={service.translations[0].postStatus}
                    data={actionData}
                    disabled={disableWhenLoading(navigation)}
                  >
                    {postStatus.map((post) => (
                      <option value={post.value} key={post.value}>
                        {post.name}
                      </option>
                    ))}
                  </FormGroup.Select>
                </div>
                <HiddenInput />
                <CButton
                  rounded
                  variant='primary'
                  type='submit'
                  disabled={disableButton(navigation)}
                  className='mt-6'
                >
                  {handleNavigationStatus(navigation, 'Submit')}
                </CButton>
              </FormGroup>
            </Form>
          )}
        </Card>
      )}
    </Container>
  );
}

export async function loader({ params }: LoaderFunctionArgs): Promise<LoaderFunction> {
  return await queryClient.fetchQuery({
    queryKey: ['services', params.id],
    queryFn: ({ signal }) =>
      getById({ signal, resource: `services/${params.id}` }),
  });
}

export async function action({ request, params }: ActionFunctionArgs): Promise<ActionFunction> {
  await queryClient.invalidateQueries({ queryKey: ['services'] });
  return await upsertResource({
    request,
    resource: `services/${params.id}`,
    isFormData: true,
    action: 'update',
  });
}
