import { LockClosedIcon } from '@heroicons/react/20/solid';
import {
  FormGroup,
  CButton,
  handleNavigationStatus,
  disableButton,
} from '@maw/utility';
import {
  ActionFunction,
  ActionFunctionArgs,
  Form,
  Link,
  useActionData,
  useNavigation,
  useParams,
} from 'react-router-dom';
import { handlePasswordReset } from '../../../util/request';
import { useRedirect } from '../../../hooks/redirect';
import HiddenInput from '../../HiddenInput';
import { disableWhenLoading } from '../../../util/helper';
import Alert from '../../Alert';

export default function PasswordReset() {
  const actionData: any = useActionData();
  const { token } = useParams();
  const navigation = useNavigation();
  useRedirect(actionData, '/', true);

  return (
    <main className='relative place-items-center bg-indigo-600 w-screen h-screen'>
      <div className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-full h-full border-none rounded-none sm:w-full md:w-96 md:h-fit md:shadow-lg shadow-slate-300 bg-white md:border-2 md:ring-2 md:ring-indigo-500 md:rounded-lg p-4'>
        <div className='flex flex-shrink-0 justify-center mb-10'>
          <LockClosedIcon className='h-10 w-10 text-indigo-600' aria-hidden />
        </div>
        <Form method='post'>
          <Alert data={actionData} />
          <FormGroup>
            <FormGroup.Input
              labelclassname='bg-white'
              type='password'
              id='password'
              title='Password'
              placeholder='Enter new password'
              data={actionData}
              disabled={disableWhenLoading(navigation)}
            />
            <FormGroup.Input
              labelclassname='bg-white'
              type='password'
              id='password_confirmation'
              title='Confirm Password'
              placeholder='Enter new password'
              data={actionData}
              disabled={disableWhenLoading(navigation)}
            />
            <HiddenInput id='token' value={token} />
            <Link
              to='/'
              className='inline-block float-right text-indigo-500 hover:text-indigo-600 hover:font-bold transition-all'
            >
              Back to login?
            </Link>
            {/* prevent bot from submitting form! */}
            <input type='hidden' name='isBot' id='isBot' />
            <CButton
              variant='primary'
              rounded
              type='submit'
              size='full'
              className='mt-6'
              disabled={disableButton(navigation)}
            >
              {handleNavigationStatus(navigation, 'Submit')}
            </CButton>
          </FormGroup>
        </Form>
      </div>
    </main>
  );
}

export async function action({
  request,
}: ActionFunctionArgs): Promise<ActionFunction> {
  return await handlePasswordReset(request);
}
