import { TrashIcon } from '@heroicons/react/20/solid';
import { ENV } from '../util/constant';
import placeholderImage from '../assets/placeholder-image.png';
import IconButton from './IconButton';

type ContactCardProps = {
  allowDelete?: boolean;
  onDelete?: () => void;
  className?: string;
};

type T = CompanyAbout | CompanyCulture;

export default function ImageCard({
  coverImage,
  allowDelete,
  onDelete,
  className,
}: Pick<T, 'id' | 'coverImage' | 'translations'> & ContactCardProps) {
  return (
    <figure
      className={`${
        className && className
      } relative w-full md:w-96 h-80 rounded-lg hover:shadow-lg overflow-hidden`}
      style={{
        backgroundImage: `url(${
          !coverImage ? placeholderImage : `${ENV.imageBaseUrl}/${coverImage}`
        })`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundColor: 'gray',
        backgroundRepeat: 'no-repeat',
        backgroundBlendMode: 'overlay',
      }}
    >
      {allowDelete && (
        <IconButton
          className='absolute right-2 top-2'
          onClick={onDelete}
          icon={
            <TrashIcon
              className='bg-gray-500 bg-opacity-50 rounded-full h-8 w-8 p-1 text-red-500 hover:text-red-600 hover:bg-white transition-colors'
              aria-hidden='true'
            />
          }
        />
      )}
    </figure>
  );
}
