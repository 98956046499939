import { ENV } from '../util/constant';
import imagePlaceholder from '../assets/generic-avatar.png';
import IconButton from './IconButton';
import { TrashIcon } from '@heroicons/react/24/outline';

export default function Avatar({
  imageUrl,
  onDelete,
  className
}: {
  imageUrl: string;
  className?: string;
  onDelete: () => void;
}) {
  return (
    <div className={`${className && className } relative w-20 h-20 overflow-hidden rounded-full`}>
      <img
        className='h-20 w-20 rounded-full object-cover'
        src={`${!imageUrl ? imagePlaceholder : ENV.imageBaseUrl}/${imageUrl}`}
        alt='User'
      />
      <IconButton
        className='absolute top-1 right-1'
        onClick={onDelete}
        icon={
          <TrashIcon
            className='bg-gray-500 bg-opacity-50 rounded-full h-8 w-8 p-1 text-red-500 hover:text-red-600 hover:bg-white transition-colors'
            aria-hidden='true'
          />
        }
      />
    </div>
  );
}
