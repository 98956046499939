import { Fragment, useContext } from 'react';
import {
  Disclosure,
  DisclosureButton,
  Menu,
  MenuItems,
  MenuItem,
  MenuButton,
  Transition,
} from '@headlessui/react';
import { Bars3Icon, BellIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { classNames } from '@maw/utility';
import { NavLink } from 'react-router-dom';
import { AuthContext } from '../store/auth';

import avatar from '../assets/generic-avatar.png';
import { ENV, ROLES } from '../util/constant';

type NavHeaderProps = {
  className?: string;
  toggleSidebarDisplay: () => void;
};

export default function NavHeader({
  className,
  toggleSidebarDisplay,
}: NavHeaderProps) {
  const authCtx = useContext(AuthContext);
  let profileNavigation = [
    { name: 'Profile', to: `/dashboard/settings/profile/${authCtx.user?.id}` },
    { name: 'Settings', to: '/dashboard/settings' },
  ];

  profileNavigation =
    authCtx.user?.role === ROLES.super
      ? [
          {
            name: 'Profile',
            to: `/dashboard/settings/profile/${authCtx.user?.id}`,
          },
          { name: 'Settings', to: '/dashboard/settings' },
        ]
      : [
          {
            name: 'Profile',
            to: `/dashboard/settings/profile/${authCtx.user?.id}`,
          },
        ];

  return (
    <Disclosure
      as='nav'
      className={`${className && className} border-y-2 border-gray-200`}
    >
      {({ open }) => (
        <>
          <div className='mx-auto px-2'>
            <div className='relative flex h-16 items-center justify-between'>
              <div className='absolute inset-y-0 left-0 flex items-center md:hidden'>
                <DisclosureButton
                  className='relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 focus:outline-none'
                  onClick={toggleSidebarDisplay}
                >
                  <span className='absolute -inset-0.5' />
                  <span className='sr-only'>Open main menu</span>
                  {open ? (
                    <XMarkIcon
                      className='block h-6 w-6'
                      aria-hidden='true'
                      // onClick={onClose}
                    />
                  ) : (
                    <Bars3Icon
                      className='block h-6 w-6'
                      aria-hidden='true'
                      // onClick={onOpen}
                    />
                  )}
                </DisclosureButton>
              </div>
              <div className='flex flex-1 items-center justify-center sm:items-stretch sm:justify-start'>
                &nbsp;
              </div>
              <div className='absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0'>
                <button
                  type='button'
                  className='relative rounded-full p-1 text-gray-400 focus:outline-none'
                >
                  <span className='absolute -inset-1.5' />
                  <span className='sr-only'>View notifications</span>
                  <BellIcon className='h-6 w-6' aria-hidden='true' />
                </button>

                {/* Profile dropdown */}
                <Menu as='div' className='relative ml-3'>
                  <div>
                    <MenuButton className='relative flex rounded-full text-sm focus:outline-none'>
                      <span className='absolute -inset-1.5' />
                      <span className='sr-only'>Open user menu</span>
                      {authCtx && authCtx?.user?.avatar ? (
                        <img
                          className='h-8 w-8 rounded-full'
                          src={`${ENV.imageBaseUrl}/${authCtx.user.avatar}`}
                          alt='User'
                        />
                      ) : (
                        <img
                          className='h-8 w-8 rounded-full'
                          src={avatar}
                          alt='User'
                        />
                      )}
                    </MenuButton>
                  </div>
                  <Transition
                    as={Fragment}
                    enter='transition ease-out duration-100'
                    enterFrom='transform opacity-0 scale-95'
                    enterTo='transform opacity-100 scale-100'
                    leave='transition ease-in duration-75'
                    leaveFrom='transform opacity-100 scale-100'
                    leaveTo='transform opacity-0 scale-95'
                  >
                    <MenuItems className='absolute overflow-hidden right-0 z-10 mt-2 w-max origin-top-right rounded-md bg-white shadow-lg'>
                      {profileNavigation.map((navigation) => (
                        <MenuItem key={navigation.name}>
                          {({ focus }) => (
                            <NavLink
                              to={navigation.to}
                              className={classNames(
                                focus ? 'bg-gray-100 hover:bg-gray-200' : '',
                                'block px-4 py-2 text-sm text-gray-700'
                              )}
                            >
                              {navigation.name}
                            </NavLink>
                          )}
                        </MenuItem>
                      ))}
                      <MenuItem>
                        {({ focus }) => (
                          <NavLink
                            onClick={() => authCtx.logout()}
                            to='#'
                            className={classNames(
                              focus ? 'bg-gray-100 hover:bg-gray-200' : '',
                              'block px-4 py-2 text-sm text-gray-700'
                            )}
                          >
                            Sign out
                          </NavLink>
                        )}
                      </MenuItem>
                    </MenuItems>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>
        </>
      )}
    </Disclosure>
  );
}
