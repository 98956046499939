export default function HiddenInput({
  value,
  id,
}: {
  value?: string;
  id?: string;
}) {
  return (
    <input
      type='hidden'
      name={id ?? '_method'}
      value={value ?? 'PUT'}
      id={id ?? '_method'}
    />
  );
}
