import {
  CButton,
  FormGroup,
  disableButton,
  handleNavigationStatus,
} from '@maw/utility';
import {
  ActionFunction,
  ActionFunctionArgs,
  Form,
  LoaderFunction,
  LoaderFunctionArgs,
  useActionData,
  useNavigation,
  useParams,
} from 'react-router-dom';
import Card from '../../../Card';
import { getById, queryClient, upsertResource } from '../../../../util/request';
import Container from '../../../Container';
import Alert from '../../../Alert';
import { useRedirect } from '../../../../hooks/redirect';
import { postStatus } from '../../../../util/constant';
import { useQuery } from '@tanstack/react-query';
import HiddenInput from '../../../HiddenInput';
import { disableWhenLoading } from '../../../../util/helper';

export default function TrustBadgeDetails() {
  const navigation = useNavigation();
  const actionData: any = useActionData();
  const params = useParams();
  const { data } = useQuery({
    queryKey: ['trust-badges', params.id],
    queryFn: ({ signal }) =>
      getById({ signal, resource: `trust-badges/${params.id}` }),
  });

  useRedirect(actionData, 'trust-badges', true);
  const trustBadge: TrustBadge = data?.data;

  return (
    <Container>
      <Alert data={actionData} />
      <Container.Heading>Trust Badge Details</Container.Heading>
      <Container.SubHeading>
        Authorized user can write the file.
      </Container.SubHeading>
      {trustBadge && (
        <Card>
          <Card.Header>Trust Badge Details</Card.Header>
          {trustBadge && (
            <Form method='post' encType='multipart/form-data' noValidate>
              <FormGroup>
                <FormGroup.Input
                  labelclassname='bg-gray-100'
                  className='flex-1'
                  type='text'
                  id='name'
                  title='Badge name'
                  placeholder='E.g Hesbuger'
                  defaultValue={trustBadge.name}
                  data={actionData}
                  disabled={disableWhenLoading(navigation)}
                />
                <FormGroup.Input
                  labelclassname='bg-gray-100'
                  className='flex-1'
                  type='file'
                  id='image'
                  title='Image (MaxHeight: 500, MaxWidth: 500)'
                  accept='.jpeg, .png, .jpg, .svg'
                  data={actionData}
                  disabled={disableWhenLoading(navigation)}
                />
                <FormGroup.Select
                  labelclassname='bg-gray-100'
                  className='flex-1'
                  id='post_status'
                  title='Post status'
                  defaultValue={trustBadge.postStatus}
                  data={actionData}
                  disabled={disableWhenLoading(navigation)}
                >
                  {postStatus.map((status) => (
                    <option value={status.value} key={status.value}>
                      {status.name}
                    </option>
                  ))}
                </FormGroup.Select>
                <HiddenInput />
                <CButton
                  rounded
                  variant='primary'
                  type='submit'
                  disabled={disableButton(navigation)}
                  className='mt-6'
                >
                  {handleNavigationStatus(navigation, 'Submit')}
                </CButton>
              </FormGroup>
            </Form>
          )}
        </Card>
      )}
    </Container>
  );
}

export async function loader({
  params,
}: LoaderFunctionArgs): Promise<LoaderFunction> {
  return await queryClient.fetchQuery({
    queryKey: ['trust-badges', params.id],
    queryFn: ({ signal }) =>
      getById({ signal, resource: `trust-badges/${params.id}` }),
  });
}

export async function action({ request, params }: ActionFunctionArgs): Promise<ActionFunction> {
  await queryClient.invalidateQueries({ queryKey: ['trust-badges'] });
  return await upsertResource({
    request,
    resource: `trust-badges/${params.id}`,
    isFormData: true,
    action: 'update',
  });
}
