import { useState, useEffect, useCallback } from 'react';
import { STATUS_CODE } from '@maw/utility';
import { ENV, USER_STATUS } from '../util/constant';
import { clearSession, getDataFromStorage } from '../util/helper';
import { ContextUser } from '../types/user';

export const useAuth = () => {
  const [token, setToken] = useState<string>();
  const [user, setUser] = useState<ContextUser>();

  const login = useCallback((user: ContextUser, token: string) => {
    setUser(user);
    setToken(token);
    sessionStorage.setItem(
      'user',
      JSON.stringify({
        user,
        token,
      })
    );
  }, []);

  const logout = useCallback(async () => {
    const storedData = getDataFromStorage();
    if (!storedData) return; // Ensure storedData exists

    const res = await fetch(`${ENV.adminBaseUrl}/auth/logout`, {
      headers: {
        Authorization: `Bearer ${storedData.token}`,
        'X-Client-Token': `${ENV.requestToken}`,
        'Accept-Language': 'en',
        Accept: 'application/json',
      },
    });
    if (res.ok) {
      clearSession();
    }
    clearSession();
  }, []);

  useEffect(() => {
    const storedData = getDataFromStorage();
    if (!storedData || !storedData.user || !storedData.token) {
      return; // Ensure storedData is valid
    }

    const fetchUser = async () => {
      try {
        const response = await fetch(
          `${ENV.adminBaseUrl}/users/${storedData.user.id}/context/pool`,
          {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${storedData.token}`,
              'X-Client-Token': `${ENV.requestToken}`,
              'Accept-Language': 'en',
              'Cache-Control': 'no-cache',
              Accept: 'application/json',
            },
          }
        );

        if (!response.ok) throw response;

        const responseData = await response.json();
        const userData = responseData.data;
        const freshUser = {
          id: userData.id,
          firstName: userData.firstName,
          lastName: userData.lastName,
          avatar: userData.avatar,
          role: userData.role,
          status: userData.status,
        };

        if (freshUser.status !== USER_STATUS.clear) {
          clearSession();
        }

        if (!objectsAreEqual(freshUser, storedData.user)) {
          login(freshUser, storedData.token);
        }
      } catch (error: any) {
        if (
          error &&
          [
            STATUS_CODE.tooManyRequests,
            STATUS_CODE.serviceUnavailable,
            STATUS_CODE.unauthorized,
            STATUS_CODE.internalServerError,
            STATUS_CODE.methodNotAllowed,
          ].includes(error.status)
        ) {
          clearSession();
        }
      }
    };

    const intervalId = setInterval(fetchUser, 120000); // 2 minutes!

    return () => clearInterval(intervalId); // Clear interval on component unmount
  }, [login]);

  useEffect(() => {
    const storedData = getDataFromStorage();
    if (storedData) {
      if (
        !!storedData.token.length &&
        storedData.user.status === USER_STATUS.clear
      ) {
        login(storedData.user, storedData.token);
      }
    }
  }, [login]);

  function objectsAreEqual(freshData: ContextUser, contextData: ContextUser) {
    return (
      freshData.avatar === contextData.avatar &&
      freshData.firstName === contextData.firstName &&
      freshData.id === contextData.id &&
      freshData.lastName === contextData.lastName &&
      freshData.role === contextData.role &&
      freshData.status === contextData.status
    );
  }

  return {
    user,
    token,
    login,
    logout,
  };
};
