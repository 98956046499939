import {
  CButton,
  FormGroup,
  disableButton,
  handleNavigationStatus,
} from '@maw/utility';
import {
  ActionFunction,
  ActionFunctionArgs,
  Form,
  LoaderFunction,
  LoaderFunctionArgs,
  useActionData,
  useNavigation,
  useParams,
} from 'react-router-dom';
import Card from '../../../Card';
import { getById, queryClient, upsertResource } from '../../../../util/request';
import Container from '../../../Container';
import Alert from '../../../Alert';
import { ENV, postStatus } from '../../../../util/constant';
import { useRedirect } from '../../../../hooks/redirect';
import { useQuery } from '@tanstack/react-query';
import { disableWhenLoading } from '../../../../util/helper';

export default function RecruitmentDetails() {
  const navigation = useNavigation();
  const actionData: any = useActionData();
  const params = useParams();
  const { data } = useQuery({
    queryKey: ['recruitments', params.id],
    queryFn: ({ signal }) =>
      getById({ signal, resource: `recruitments/${params.id}` }),
  });
  useRedirect(actionData, 'recruitments', true);
  const recruitment: Recruitment = data?.data;

  return (
    <Container>
      <Alert data={actionData} />
      <Container.Heading>Job Details</Container.Heading>
      <Container.SubHeading>
        Authorized user can write the file.
      </Container.SubHeading>
      {recruitment && (
        <Card>
          <Card.Header>{recruitment.title}</Card.Header>
          <Form method='post' noValidate>
            <FormGroup>
              <FormGroup.Input
                labelclassname='bg-gray-100'
                type='text'
                id='title'
                title='Title'
                placeholder='Enter recruitment title'
                defaultValue={recruitment.title}
                data={actionData}
                disabled={disableWhenLoading(navigation)}
              />
              <FormGroup.Editor
                labelclassname='bg-gray-100'
                apiKey={ENV.editorApiKey}
                id='description'
                initialValue={recruitment.description}
                data={actionData}
                disabled={disableWhenLoading(navigation)}
              />
              <FormGroup.Input
                labelclassname='bg-gray-100'
                type='date'
                id='expires_at'
                title='Expiration date'
                defaultValue={
                  new Date(recruitment.expiresAt).toISOString().split('T')[0]
                }
                data={actionData}
                disabled={disableWhenLoading(navigation)}
              />
              <FormGroup.Input
                labelclassname='bg-gray-100'
                type='text'
                id='location'
                title='Location'
                placeholder='Enter location'
                defaultValue={recruitment.location}
                data={actionData}
                disabled={disableWhenLoading(navigation)}
              />
              <FormGroup.Select
                labelclassname='bg-gray-100'
                id='post_status'
                title='Post status'
                defaultValue={recruitment.postStatus}
                data={actionData}
                disabled={disableWhenLoading(navigation)}
              >
                {postStatus.map((post) => (
                  <option value={post.value} key={post.value}>
                    {post.name}
                  </option>
                ))}
              </FormGroup.Select>
              <CButton
                rounded
                variant='primary'
                type='submit'
                disabled={disableButton(navigation)}
                className='mt-6'
              >
                {handleNavigationStatus(navigation, 'Submit')}
              </CButton>
            </FormGroup>
          </Form>
        </Card>
      )}
    </Container>
  );
}

export async function loader({
  params,
}: LoaderFunctionArgs): Promise<LoaderFunction> {
  return await queryClient.fetchQuery({
    queryKey: ['recruitments', params.id],
    queryFn: ({ signal }) =>
      getById({ signal, resource: `recruitments/${params.id}` }),
  });
}

export async function action({
  request,
  params,
}: ActionFunctionArgs): Promise<ActionFunction> {
  await queryClient.invalidateQueries({ queryKey: ['recruitments'] });
  return await upsertResource({
    request,
    resource: `recruitments/${params.id}`,
    isFormData: false,
    action: 'update',
  });
}
