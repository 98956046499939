import { TrashIcon } from '@heroicons/react/20/solid';
import { ENV } from '../util/constant';
import placeholderImage from '../assets/placeholder-image.png';
import IconButton from './IconButton';

type LogoCardProps = {
  allowDelete?: boolean;
  onDelete?: () => void;
  className?: string;
};

export default function LogoCard({
  id,
  logo,
  allowDelete,
  companyName,
  onDelete,
  className,
}: Pick<Customer, 'id' | 'logo' | 'companyName'> & LogoCardProps) {
  return (
    <figure className={`${className && className} relative overflow-hidden`}>
      <img
        src={`${logo ? `${ENV.imageBaseUrl}/${logo}` : placeholderImage}`}
        alt={companyName}
        className='object-cover w-full rounded-t-lg'
      />

      {allowDelete && (
        <div className='flex absolute right-4 top-2'>
          <IconButton
            onClick={onDelete}
            icon={
              <TrashIcon
                className='bg-gray-500 bg-opacity-50 rounded-full h-8 w-8 p-1 text-red-500 hover:text-red-600 hover:bg-white transition-colors'
                aria-hidden='true'
              />
            }
          />
        </div>
      )}
    </figure>
  );
}
