import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Layout from './components/Layout';
import ErrorBoundary from './components/ErrorBoundary';
import Dashboard from './components/Dashboard';
import Login, { action as loginAction } from './components/views/pages/Login';
import PasswordResetRequest, {
  action as passwordResetRequestAction,
} from './components/views/pages/PasswordResetRequest';
import PasswordReset, {
  action as passwordResetAction,
} from './components/views/pages/PasswordReset';
import NewUser from './components/views/pages/users/NewUser';
import { action as newUserAction } from './components/views/pages/users/NewUser';
import Users, {
  loader as usersLoader,
} from './components/views/pages/users/Users';
import AccountVerification, {
  action as accountVerificationAction,
} from './components/views/pages/AccountVerification';
import User, {
  action as userAction,
} from './components/views/pages/users/UserDetails';
import { loader as userLoader } from './components/views/pages/users/UserDetails';

import Recruitments, {
  loader as jobsLoader,
} from './components/views/pages/recruitments/Recruitments';
import RecruitmentDetails, {
  action as jobAction,
  loader as jobLoader,
} from './components/views/pages/recruitments/RecruitmentDetails';
import NewRecruitment, {
  action as newJobAction,
} from './components/views/pages/recruitments/NewRecruitment';
import Services, {
  loader as servicesLoader,
} from './components/views/pages/services/Services';
import NewService, {
  action as newServiceAction,
} from './components/views/pages/services/NewService';
import Service, {
  action as serviceAction,
  loader as serviceLoader,
} from './components/views/pages/services/ServiceDetails';
import Contacts, {
  loader as contactsLoader,
} from './components/views/pages/contacts/Contacts';
import NewContact, {
  action as newContactAction,
} from './components/views/pages/contacts/NewContact';
import ContactDetails, {
  loader as contactLoader,
  action as contactAction,
} from './components/views/pages/contacts/ContactDetails';
import Testimonials, {
  loader as testimonialsLoader,
} from './components/views/pages/testimonials/Testimonials';
import NewTestimonial, {
  action as newTestimonialAction,
} from './components/views/pages/testimonials/NewTestimonial';
import TestimonialDetails, {
  loader as testimonialLoader,
  action as testimonialAction,
} from './components/views/pages/testimonials/TestimonialDetails';
import BlogPosts, {
  loader as blogPostsLoader,
} from './components/views/pages/blog/BlogPosts';
import NewBlogPost, {
  action as newBlogAction,
  loader as newBlogPostLoader,
} from './components/views/pages/blog/NewBlogPost';
import BlogPostDetails, {
  loader as blogPostDetailsLoader,
  action as blogPostDetailsAction,
} from './components/views/pages/blog/BlogPostDetails';
import BlogCategories, {
  loader as blogCategoriesLoader,
} from './components/views/pages/blog/BlogCategories';
import NewBlogCategory, {
  action as newBlogCategoryAction,
} from './components/views/pages/blog/NewBlogCategory';
import BlogCategoryDetails, {
  loader as blogCategoryloader,
  action as blogCategoryAction,
} from './components/views/pages/blog/BlogCategoryDetails';
import TrustBadges, {
  loader as trustBadgesLoader,
} from './components/views/pages/trust-badges/TrustBadges';
import NewTrustBadge, {
  action as newTrustBadgeAction,
} from './components/views/pages/trust-badges/NewTrustBadge';
import TrustBadgeDetails, {
  loader as trustBadgeLoader,
  action as trustBadgeAction,
} from './components/views/pages/trust-badges/TrustBadgeDetails';
import ProfileSettings, {
  loader as profileLoader,
  action as profileAction,
} from './components/views/pages/settings/Profile';
import Assets, {
  loader as assetsLoader,
} from './components/views/pages/assets/Assets';
import NewAsset, {
  action as newAssetAction,
} from './components/views/pages/assets/NewAsset';
import AssetDetails, {
  loader as assetLoader,
} from './components/views/pages/assets/AssetDetails';
import Employees, {
  loader as employeesLoader,
} from './components/views/pages/employees/Employees';
import NewEmployee, {
  action as newEmployeeAction,
} from './components/views/pages/employees/NewEmployee';
import EmployeeDetails, {
  action as employeeAction,
  loader as employeeLoader,
} from './components/views/pages/employees/EmployeeDetails';
import CompanyAbouts, {
  loader as companyAboutsLoader,
} from './components/views/pages/company-details/CompanyAbouts';
import NewCompanyAbout, {
  action as newCompanyAboutAction,
} from './components/views/pages/company-details/NewCompanyAbout';
import CompanyAboutDetails, {
  action as companyAboutAction,
  loader as companyAboutLoader,
} from './components/views/pages/company-details/CompanyAboutDetails';
import CompanyCultures, {
  loader as companyCulturesLoader,
} from './components/views/pages/company-details/CompanyCultures';
import NewCompanyCulture, {
  action as newCompanyCultureAction,
} from './components/views/pages/company-details/NewCompanyCulture';
import CompanyCultureDetails, {
  loader as companyCultureLoader,
  action as companyCultureAction,
} from './components/views/pages/company-details/CompanyCultureDetails';
import Settings, {
  loader as maintenanceStatusLoader,
  action as toggleMaintenanceStatusAction,
} from './components/views/pages/settings/Settings';
import NewCustomer, {
  action as newCustomerAction,
} from './components/views/pages/customers/NewCustomer';
import CustomerDetails, {
  loader as customerLoader,
  action as customerAction,
} from './components/views/pages/customers/CustomerDetails';
import Customers, {
  loader as customersLoader,
} from './components/views/pages/customers/Customers';

export default function App() {
  const router = createBrowserRouter([
    {
      path: '/dashboard',
      element: <Layout />,
      errorElement: <ErrorBoundary />,
      children: [
        {
          index: true,
          element: <Dashboard />,
        },
        {
          path: 'users',
          element: <Users />,
          loader: usersLoader,
        },
        {
          path: 'users/new',
          element: <NewUser />,
          action: newUserAction,
        },
        {
          path: 'users/:id',
          element: <User />,
          loader: userLoader,
          action: userAction,
        },
        {
          path: 'settings/profile/:id',
          element: <ProfileSettings />,
          loader: profileLoader,
          action: profileAction,
        },
        {
          path: 'settings',
          element: <Settings />,
          loader: maintenanceStatusLoader,
          action: toggleMaintenanceStatusAction,
        },
        {
          path: 'recruitments',
          element: <Recruitments />,
          loader: jobsLoader,
        },
        {
          path: 'recruitments/new',
          element: <NewRecruitment />,
          action: newJobAction,
        },
        {
          path: 'recruitments/:id',
          element: <RecruitmentDetails />,
          loader: jobLoader,
          action: jobAction,
        },
        {
          path: 'services',
          element: <Services />,
          loader: servicesLoader,
        },
        {
          path: 'services/new',
          element: <NewService />,
          action: newServiceAction,
        },
        {
          path: 'services/:id',
          element: <Service />,
          loader: serviceLoader,
          action: serviceAction,
        },
        {
          path: 'contacts',
          element: <Contacts />,
          loader: contactsLoader,
        },
        {
          path: 'contacts/new',
          element: <NewContact />,
          action: newContactAction,
        },
        {
          path: 'contacts/:id',
          element: <ContactDetails />,
          loader: contactLoader,
          action: contactAction,
        },
        {
          path: 'testimonials',
          element: <Testimonials />,
          loader: testimonialsLoader,
        },
        {
          path: 'testimonials/new',
          element: <NewTestimonial />,
          action: newTestimonialAction,
        },
        {
          path: 'testimonials/:id',
          element: <TestimonialDetails />,
          loader: testimonialLoader,
          action: testimonialAction,
        },
        {
          path: 'blog-posts',
          element: <BlogPosts />,
          loader: blogPostsLoader,
        },
        {
          path: 'blog-posts/new',
          element: <NewBlogPost />,
          action: newBlogAction,
          loader: newBlogPostLoader,
        },
        {
          path: 'blog-posts/:slug',
          element: <BlogPostDetails />,
          loader: blogPostDetailsLoader,
          action: blogPostDetailsAction,
        },
        {
          path: 'blog-categories',
          element: <BlogCategories />,
          loader: blogCategoriesLoader,
        },
        {
          path: 'blog-categories/new',
          element: <NewBlogCategory />,
          action: newBlogCategoryAction,
        },
        {
          path: 'blog-categories/:slug',
          element: <BlogCategoryDetails />,
          loader: blogCategoryloader,
          action: blogCategoryAction,
        },
        {
          path: 'trust-badges',
          element: <TrustBadges />,
          loader: trustBadgesLoader,
        },
        {
          path: 'trust-badges/new',
          element: <NewTrustBadge />,
          action: newTrustBadgeAction,
        },
        {
          path: 'trust-badges/:id',
          element: <TrustBadgeDetails />,
          loader: trustBadgeLoader,
          action: trustBadgeAction,
        },
        {
          path: 'assets',
          element: <Assets />,
          loader: assetsLoader,
        },
        {
          path: 'assets/new',
          element: <NewAsset />,
          action: newAssetAction,
        },
        {
          path: 'assets/:id',
          element: <AssetDetails />,
          loader: assetLoader,
        },
        {
          path: 'employees',
          element: <Employees />,
          loader: employeesLoader,
        },
        {
          path: 'employees/new',
          element: <NewEmployee />,
          action: newEmployeeAction,
        },
        {
          path: 'employees/:id',
          element: <EmployeeDetails />,
          loader: employeeLoader,
          action: employeeAction,
        },
        {
          path: 'company-abouts',
          element: <CompanyAbouts />,
          loader: companyAboutsLoader,
        },
        {
          path: 'company-abouts/new',
          element: <NewCompanyAbout />,
          action: newCompanyAboutAction,
        },
        {
          path: 'company-abouts/:id',
          element: <CompanyAboutDetails />,
          loader: companyAboutLoader,
          action: companyAboutAction,
        },
        {
          path: 'company-cultures',
          element: <CompanyCultures />,
          loader: companyCulturesLoader,
        },
        {
          path: 'company-cultures/new',
          element: <NewCompanyCulture />,
          action: newCompanyCultureAction,
        },
        {
          path: 'company-cultures/:id',
          element: <CompanyCultureDetails />,
          loader: companyCultureLoader,
          action: companyCultureAction,
        },
        {
          path: 'customers',
          element: <Customers />,
          loader: customersLoader,
        },
        {
          path: 'customers/new',
          element: <NewCustomer />,
          action: newCustomerAction,
        },
        {
          path: 'customers/:id',
          element: <CustomerDetails />,
          loader: customerLoader,
          action: customerAction,
        },
      ],
    },
    {
      path: '/',
      errorElement: <ErrorBoundary />,
      element: <Login />,
      action: loginAction,
    },
    {
      path: 'passwords/request-reset',
      element: <PasswordResetRequest />,
      action: passwordResetRequestAction,
    },
    {
      path: 'passwords/reset/:token',
      element: <PasswordReset />,
      action: passwordResetAction,
    },
    {
      path: 'accounts/verification/:token',
      element: <AccountVerification />,
      action: accountVerificationAction,
    },
  ]);

  return <RouterProvider router={router} fallbackElement={<Layout />} />;
}
