import { QueryClient } from '@tanstack/react-query';
import { ENV } from './constant';
import { getDataFromStorage } from './helper';

export const queryClient = new QueryClient();

interface Options {
  headers?: Record<string, string>;
  body?: any;
  signal?: AbortSignal;
}

export class Http {
  private static defaultHeaders = {
    'X-Client-Token': `${ENV.requestToken}`,
    Accept: 'application/json',
    'Accept-Language': 'en',
  };

  private static async request(
    url: string,
    method: string,
    options: Options
  ): Promise<any> {
    const headers = {
      ...this.defaultHeaders,
      ...options.headers,
    };

    try {
      const response = await fetch(url, {
        method,
        headers,
        body: options.body,
        signal: options.signal,
      });
      const data = await response.json();
      if (!response.ok) {
        return data;
      }
      return data;
    } catch (error: any) {
      return { error: { message: error.statusText }, statusCode: 500 };
    }
  }

  static async post(url: string, options: Options) {
    return await this.request(url, 'POST', options);
  }

  static async get(url: string, options: Options) {
    return await this.request(url, 'GET', options);
  }

  static async delete(url: string, options: Options) {
    return await this.request(url, 'DELETE', options);
  }

  static async patch(url: string, options: Options) {
    return await this.request(url, 'PATCH', options);
  }

  static async put(url: string, options: Options) {
    return await this.request(url, 'PUT', options);
  }
}

async function getFormData(request: Request): Promise<Record<string, any>> {
  const formData = await request.formData();
  return Object.fromEntries(formData);
}

export async function handleLogin(request: Request): Promise<any> {
  const entries = await getFormData(request);
  return await Http.post(`${ENV.adminBaseUrl}/auth/login`, {
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(entries),
    signal: request.signal,
  });
}

export async function handlePasswordResetRequest(
  request: Request
): Promise<any> {
  const entries = await getFormData(request);
  return await Http.post(`${ENV.adminBaseUrl}/passwords/request-reset`, {
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(entries),
    signal: request.signal,
  });
}

export async function handlePasswordReset(
  request: Request
): Promise<any> {
  const entries = await getFormData(request);
  return await Http.post(`${ENV.adminBaseUrl}/passwords/reset`, {
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(entries),
    signal: request.signal,
  });
}

export async function getResources({
  signal,
  url,
}: {
  signal?: AbortSignal;
  url: string;
}): Promise<any> {
  const sessionData = getDataFromStorage();
  if (sessionData && sessionData.token) {
    const baseUrl = `${ENV.adminBaseUrl}/${url}`;
    return await Http.get(`${baseUrl}`, {
      headers: {
        Authorization: `Bearer ${sessionData.token}`,
      },
      signal,
    });
  }
}

export async function upsertResource({
  request,
  resource,
  isFormData,
  action,
}: {
  request: Request;
  resource: string;
  isFormData: boolean;
  action: 'update' | 'create';
}): Promise<any> {
  const sessionData = getDataFromStorage();
  const url = `${ENV.adminBaseUrl}`;
  const resourceUrl = `${url}/${resource}`;
  const formData = await request.formData();
  const entries = Object.fromEntries(formData);
  const body = isFormData ? formData : JSON.stringify(entries);

  const headers: Record<string, string> = {
    Authorization: `Bearer ${sessionData?.token}`,
  };

  if (!isFormData) {
    headers['Content-Type'] = 'application/json';
  }

  if (action === 'create' || isFormData) {
    return await Http.post(resourceUrl, {
      headers,
      body,
      signal: request.signal,
    });
  }
  return await Http.put(resourceUrl, {
    headers,
    body,
    signal: request.signal,
  });
}

export async function getById({
  signal,
  resource,
}: {
  signal: AbortSignal;
  resource: string;
}): Promise<any> {
  const sessionData = getDataFromStorage();
  if (sessionData?.token) {
    const url = `${ENV.adminBaseUrl}/${resource}`;
    return await Http.get(url, {
      headers: {
        Authorization: `Bearer ${sessionData.token}`,
      },
      signal,
    });
  }
}

export async function verifyAccount(request: Request): Promise<any> {
  const entries = await getFormData(request);
  return await Http.post(`${ENV.adminBaseUrl}/accounts/verification`, {
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(entries),
    signal: request.signal,
  });
}

export async function deleteById(resource: string): Promise<any> {
  const sessionData = getDataFromStorage();
  if (sessionData?.token && resource) {
    const url = `${ENV.adminBaseUrl}/${resource}`;
    return await Http.delete(url, {
      headers: {
        Authorization: `Bearer ${sessionData.token}`,
      },
    });
  }
}

export async function getPaginatedData({
  signal,
  currentPage,
  perPage,
  resource,
}: {
  signal: AbortSignal;
  currentPage: number;
  perPage: number;
  resource: string;
}) {
  const sessionData = getDataFromStorage();
  const url = `${ENV.adminBaseUrl}/${resource}`;
  return await Http.post(`${url}?page=${currentPage}&&per_page=${perPage}`, {
    headers: {
      Authorization: `Bearer ${sessionData.token}`,
    },
    signal,
  });
}

export async function getMaintenanceStatus() {
  const sessionData = getDataFromStorage();
  return await Http.get(`${ENV.adminBaseUrl}/operations/maintenance/status`, {
    headers: {
      Authorization: `Bearer ${sessionData.token}`,
    },
  });
}

export async function toggleMaintenanceStatus(mode: 'up' | 'down') {
  const sessionData = getDataFromStorage();
  return await Http.post(`${ENV.adminBaseUrl}/operations/maintenance/toggle`, {
    headers: {
      Authorization: `Bearer ${sessionData.token}`,
    },
    body: JSON.stringify(mode),
  });
}
