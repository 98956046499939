var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import Badge from '../components/Badge';
import { STATUS } from './constants/status';
export function capitalize(data) {
    return data.charAt(0).toUpperCase() + data.toLowerCase().slice(1);
}
export function reloadPage() {
    window.location.reload();
}
export function getBadge(status) {
    switch (status) {
        case STATUS.active:
            return 'success';
        case STATUS.pending:
            return 'warning';
        case STATUS.deleted:
            return 'danger';
        case STATUS.cancelled:
            return 'danger';
        case STATUS.closed:
            return 'danger';
        case STATUS.banned:
            return 'danger';
        case STATUS.yes:
            return 'success';
        case STATUS.no:
            return 'warning';
        case STATUS.archived:
            return 'info';
        case STATUS.draft:
            return 'warning';
        case STATUS.published:
            return 'success';
        default:
            return 'primary';
    }
}
export function replaceSpaceAndToUpperCase(data) {
    data.replace(/\s{1,}/gi, ' ');
    data.replace(/-{1,}/gi, '-');
    return data.toUpperCase();
}
export function transformedData(data) {
    return data.map(function (obj, index) {
        var _a = obj.status, status = _a === void 0 ? '' : _a, _b = obj.isPublished, isPublished = _b === void 0 ? '' : _b;
        return __assign(__assign({ count: index + 1 }, obj), { status: _jsx(Badge, { title: status, variant: getBadge(status) }), isPublished: (_jsx(Badge, { title: isPublished, variant: isPublished.toLowerCase() === 'yes' ? 'success' : 'danger' })) });
    });
}
export function getDataFromStorage() {
    var userData = sessionStorage.getItem('user');
    return userData ? JSON.parse(userData) : null;
}
export function sessionIsEmpty() {
    return JSON.parse(sessionStorage.getItem('user')) === undefined || null;
}
export function disableButton(navigation) {
    return navigation.state === 'submitting' || navigation.state === 'loading';
}
export function handleNavigationStatus(navigation, buttonValue) {
    return navigation.state === 'submitting' || navigation.state === 'loading'
        ? 'Wait...'
        : buttonValue;
}
export function clearSession() {
    sessionStorage.removeItem('user');
    window.location.replace('/');
}
export function classNames() {
    var classes = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        classes[_i] = arguments[_i];
    }
    return classes.filter(Boolean).join(' ');
}
export function handleBadgeColor(variant) {
    switch (variant) {
        case 'primary': {
            return 'bg-green-200';
        }
        case 'info': {
            return 'bg-indigo-200';
        }
        case 'warning': {
            return 'bg-yellow-200';
        }
        case 'danger': {
            return 'bg-red-200';
        }
        case 'success': {
            return 'bg-green-200';
        }
        case 'default': {
            return 'bg-slate-200';
        }
        default:
            return 'bg-slate-200';
    }
}
export function getCommonClass(_a) {
    var variant = _a.variant, outline = _a.outline, size = _a.size, pill = _a.pill, rounded = _a.rounded;
    return [
        "".concat(rounded ? 'rounded-md' : 'rounded-none'),
        "".concat(pill ? 'rounded-full' : ''),
        "".concat(getColorClass(variant, outline)),
        "".concat(size === 'sm' ? 'w-32' : ''),
        "".concat(size === 'md' ? 'w-40' : ''),
        "".concat(size === 'lg' ? 'w-48' : ''),
        "".concat(size === 'xl' ? 'w-56' : ''),
        "".concat(size === 'full' ? 'w-full' : ''),
    ]
        .filter(function (str) { return typeof str === 'string' && str.length > 0; })
        .join(' ');
}
function getColorClass(variant, outline) {
    switch (variant) {
        case 'primary':
            return outline
                ? 'text-indigo-600 bg-white hover:bg-indigo-500 hover:text-white'
                : 'bg-indigo-600 hover:bg-indigo-500 text-white';
        case 'info':
            return outline
                ? 'text-indigo-600 bg-white hover:bg-indigo-500 hover:text-white'
                : 'bg-indigo-600 hover:bg-indigo-500 text-white';
        case 'danger':
            return outline
                ? 'text-red-600 bg-white hover:bg-red-500 hover:text-white'
                : 'bg-red-600 hover:bg-red-500 text-white';
        case 'success':
            return outline
                ? 'text-green-600 bg-white hover:bg-green-500 hover:text-white'
                : 'bg-green-600 hover:bg-green-500 text-white';
        case 'warning':
            return outline
                ? 'text-yellow-400 bg-white hover:bg-yellow-300 hover:text-white'
                : 'bg-yellow-400 hover:bg-yellow-300 text-white';
        case 'default':
            return outline
                ? 'text-slate-600 bg-white hover:bg-slate-500 hover:text-white'
                : 'bg-slate-600 hover:bg-slate-500 text-white';
        default:
            return '';
    }
}
export function getTranslatedEmailPlaceholder(lang) {
    return lang === 'en' ? 'john.doe@example.com' : 'satu.saarinen@example.com';
}
export function getTranslatedPhonePlaceholder(lang) {
    return lang === 'en' ? '+44277635245' : '+35844255367279';
}
export function getTranslatedNamePlaceholder(lang, name) {
    if (lang === 'en') {
        if (name === 'first') {
            return 'John';
        }
        else if (name === 'last') {
            return 'Doe';
        }
    }
    if (lang === 'fi') {
        if (name === 'first') {
            return 'Satu';
        }
        else if (name === 'last') {
            return 'Saarinen';
        }
    }
}
