import {
  CButton,
  FormGroup,
  disableButton,
  handleNavigationStatus,
} from '@maw/utility';
import {
  ActionFunction,
  ActionFunctionArgs,
  Form,
  Link,
  Navigate,
  useActionData,
  useNavigate,
  useNavigation,
} from 'react-router-dom';
import { handleLogin } from '../../../util/request';
import { useContext, useEffect } from 'react';
import { AuthContext } from '../../../store/auth';
import { disableWhenLoading, isAuthorized } from '../../../util/helper';
import Alert from '../../Alert';

import logo from '../../../assets/logo-no-text.png'

export default function Login() {
  const actionData: any = useActionData();
  const { login } = useContext(AuthContext);
  const navigation = useNavigation();
  const navigate = useNavigate();

  useEffect(() => {
    if (actionData && actionData.statusCode === 200) {
      login(actionData.data, actionData.token);
      navigate('/dashboard');
    }
  }, [actionData, login, navigate]);

  return (
    <>
      {isAuthorized() && <Navigate to='/dashboard' replace={true} />}
      <main className='relative place-items-center bg-indigo-600 w-screen h-screen'>
        <div className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-full h-full border-none rounded-none sm:w-full md:w-96 md:h-fit md:shadow-lg shadow-slate-300 bg-white md:border-2 md:ring-2 md:ring-indigo-500 md:rounded-lg p-4'>
          <div className='w-16 mx-auto mb-6'>
            <img src={logo} alt="Logo" className='w-full'/>
          </div>
          <Form method='post' noValidate>
            <Alert data={actionData} />
            <FormGroup>
              <FormGroup.Input
                labelclassname='bg-white'
                type='email'
                id='email'
                placeholder='Email'
                title='Email'
                data={actionData}
                disabled={disableWhenLoading(navigation)}
              />
              <FormGroup.Input
                labelclassname='bg-white'
                type='password'
                id='password'
                placeholder='Password'
                title='Password'
                data={actionData}
                disabled={disableWhenLoading(navigation)}
              />
              <Link
                to='/passwords/request-reset'
                className='inline-block float-right text-indigo-500 hover:text-indigo-600 hover:font-bold transition-all'
              >
                Forgot your password?
              </Link>
              {/* prevent bot from submitting form! */}
              <input type='hidden' name='isBot' id='isBot' />
              <CButton
                type='submit'
                rounded
                variant='primary'
                size='full'
                className='mt-8'
                disabled={disableButton(navigation)}
              >
                {handleNavigationStatus(navigation, 'Submit')}
              </CButton>
            </FormGroup>
          </Form>
        </div>
      </main>
    </>
  );
}

export async function action({
  request,
}: ActionFunctionArgs): Promise<ActionFunction> {
  return await handleLogin(request);
}
