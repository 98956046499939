import {
  CButton,
  FormGroup,
  disableButton,
  handleNavigationStatus,
} from '@maw/utility';
import {
  ActionFunction,
  ActionFunctionArgs,
  Form,
  useActionData,
  useNavigation,
} from 'react-router-dom';
import Card from '../../../Card';
import { queryClient, upsertResource } from '../../../../util/request';
import Container from '../../../Container';
import Alert from '../../../Alert';
import { useRedirect } from '../../../../hooks/redirect';
import { ENV } from '../../../../util/constant';
import { disableWhenLoading } from '../../../../util/helper';

export default function NewRecruitment() {
  const actionData: any = useActionData();
  const navigation = useNavigation();

  useRedirect(actionData, 'recruitments', true);

  return (
    <Container>
      <Alert data={actionData} />
      <Container.Heading>New Job</Container.Heading>
      <Container.SubHeading>
        Authorized user can write the file.
      </Container.SubHeading>
      <Card>
        <Card.Header>New Job</Card.Header>
        <Form method='post' noValidate>
          <FormGroup>
            <FormGroup.Input
              labelclassname='bg-gray-100'
              type='text'
              id='title'
              title='Title'
              placeholder='Enter job title'
              data={actionData}
              disabled={disableWhenLoading(navigation)}
            />
            <FormGroup.Editor
              labelclassname='bg-gray-100'
              apiKey={ENV.editorApiKey}
              id='description'
              data={actionData}
              disabled={disableWhenLoading(navigation)}
            />
            <FormGroup.Input
              labelclassname='bg-gray-100'
              type='date'
              id='expires_at'
              title='Expiration date'
              min={new Date().toISOString().split('T')[0]}
              data={actionData}
              disabled={disableWhenLoading(navigation)}
            />
            <FormGroup.Input
              labelclassname='bg-gray-100'
              type='text'
              id='location'
              title='Location'
              placeholder='Enter location'
              data={actionData}
              disabled={disableWhenLoading(navigation)}
            />
            <CButton
              rounded
              variant='primary'
              type='submit'
              disabled={disableButton(navigation)}
              className='mt-6'
            >
              {handleNavigationStatus(navigation, 'Submit')}
            </CButton>
          </FormGroup>
        </Form>
      </Card>
    </Container>
  );
}

export async function action({
  request,
}: ActionFunctionArgs): Promise<ActionFunction> {
  await queryClient.invalidateQueries({ queryKey: ['recruitments'] });
  return await upsertResource({
    request,
    resource: 'recruitments',
    isFormData: false,
    action: 'create',
  });
}
