import {
  CButton,
  FormGroup,
  disableButton,
  handleNavigationStatus,
} from '@maw/utility';
import {
  ActionFunction,
  ActionFunctionArgs,
  Form,
  LoaderFunction,
  useActionData,
  useNavigation,
} from 'react-router-dom';
import Card from '../../../Card';
import {
  getResources,
  queryClient,
  upsertResource,
} from '../../../../util/request';
import Container from '../../../Container';
import Alert from '../../../Alert';
import { useRedirect } from '../../../../hooks/redirect';
import { ENV } from '../../../../util/constant';
import { useQuery } from '@tanstack/react-query';
import HiddenInput from '../../../HiddenInput';
import { useContext } from 'react';
import { AuthContext } from '../../../../store/auth';
import {
  disableWhenLoading,
  getBlogCategoriesForSelectInput,
} from '../../../../util/helper';
import ErrorAlert from '../../../ErrorAlert';

export default function NewBlogPost() {
  const actionData: any = useActionData();
  const navigation = useNavigation();
  const authCtx = useContext(AuthContext);

  const { data } = useQuery({
    queryKey: ['blog-categories'],
    queryFn: ({ signal }) =>
      getResources({ signal, url: 'blog-categories/published' }),
  });

  const blogCategories = getBlogCategoriesForSelectInput(data?.data);

  useRedirect(actionData, 'blog-posts', true);

  return (
    <Container>
      <Alert data={actionData} />
      <ErrorAlert data={actionData} />
      <Container.Heading>New blog post</Container.Heading>
      <Container.SubHeading>
        Authorized user can write the file.
      </Container.SubHeading>
      <Card>
        <Card.Header>New blog post</Card.Header>
        <Form method='post' encType='multipart/form-data' noValidate>
          <FormGroup>
            <div className='flex flex-col gap-x-2 md:flex-row md:space-x-2'>
              <div className='flex-1'>
                <p className='mb-8 text-gray-500 font-bold'>English</p>
                <FormGroup.Input
                  labelclassname='bg-gray-100'
                  type='text'
                  id='translations[0][title][en]'
                  title='Title'
                  placeholder='Enter blog title'
                  data={actionData}
                  disabled={disableWhenLoading(navigation)}
                />
                <FormGroup.Input
                  labelclassname='bg-gray-100'
                  type='file'
                  id='cover_image'
                  title='Cover image (MaxHeight: 500, MaxWidth: 800)'
                  accept='.jpeg, .png, .jpg, .svg'
                  data={actionData}
                  disabled={disableWhenLoading(navigation)}
                />
                <FormGroup.Editor
                  labelclassname='bg-gray-100'
                  apiKey={ENV.editorApiKey}
                  id='translations[0][content][en]'
                  data={actionData}
                  disabled={disableWhenLoading(navigation)}
                />
                <HiddenInput value={authCtx?.user?.id} id='user_id' />
              </div>
              <div className='flex-1'>
                <p className='mb-8 text-gray-500 font-bold'>Finnish</p>
                <FormGroup.Input
                  labelclassname='bg-gray-100'
                  type='text'
                  id='translations[0][title][fi]'
                  title='Title'
                  placeholder='Enter blog title'
                  data={actionData}
                  disabled={disableWhenLoading(navigation)}
                />
                <FormGroup.Select
                  labelclassname='bg-gray-100'
                  id='blog_category_id'
                  title='Blog category'
                  data={actionData}
                  disabled={disableWhenLoading(navigation)}
                >
                  {blogCategories.map((blogCategory) => (
                    <option value={blogCategory.id} key={blogCategory.id}>
                      {blogCategory.name}
                    </option>
                  ))}
                </FormGroup.Select>
                <FormGroup.Editor
                  labelclassname='bg-gray-100'
                  apiKey={ENV.editorApiKey}
                  id='translations[0][content][fi]'
                  data={actionData}
                  disabled={disableWhenLoading(navigation)}
                />
              </div>
            </div>
            <CButton
              rounded
              variant='primary'
              type='submit'
              disabled={disableButton(navigation)}
              className='mt-6'
            >
              {handleNavigationStatus(navigation, 'Submit')}
            </CButton>
          </FormGroup>
        </Form>
      </Card>
    </Container>
  );
}

export async function loader(): Promise<LoaderFunction> {
  return await queryClient.fetchQuery({
    queryKey: ['blog-categories'],
    queryFn: ({ signal }) =>
      getResources({ signal, url: 'blog-categories/published' }),
  });
}

export async function action({
  request,
}: ActionFunctionArgs): Promise<ActionFunction> {
  await queryClient.invalidateQueries({ queryKey: ['blog-posts'] });
  return await upsertResource({
    request,
    resource: 'blog-posts',
    isFormData: true,
    action: 'create',
  });
}
