import {
  CButton,
  FormGroup,
  disableButton,
  handleNavigationStatus,
} from '@maw/utility';
import {
  ActionFunction,
  ActionFunctionArgs,
  Form,
  useActionData,
  useNavigation,
} from 'react-router-dom';
import Card from '../../../Card';
import { queryClient, upsertResource } from '../../../../util/request';
import Container from '../../../Container';
import { useRedirect } from '../../../../hooks/redirect';
import Alert from '../../../Alert';
import { ENV, serviceCategories } from '../../../../util/constant';
import { disableWhenLoading } from '../../../../util/helper';
import ErrorAlert from '../../../ErrorAlert';

export default function NewService() {
  const actionData: any = useActionData();
  const navigation = useNavigation();

  useRedirect(actionData, 'services', true);

  return (
    <Container>
      <ErrorAlert data={actionData} />
      <Alert data={actionData} />
      <Container.Heading>New Service</Container.Heading>
      <Container.SubHeading>
        Authorized user can write the file.
      </Container.SubHeading>
      <Card>
        <Card.Header>New service</Card.Header>
        <Form method='post' encType='multipart/form-data' noValidate>
          <FormGroup>
            <div className='flex flex-col gap-x-2 md:flex-row md:space-x-2'>
              <div className='flex-1'>
                <p className='mb-8 text-gray-500 font-bold'>English</p>
                <FormGroup.Input
                  labelclassname='bg-gray-100'
                  type='text'
                  id='translations[0][title][en]'
                  title='Title'
                  placeholder='Enter title'
                  data={actionData}
                  disabled={disableWhenLoading(navigation)}
                />
                <FormGroup.Input
                  labelclassname='bg-gray-100'
                  type='text'
                  id='translations[0][sub_title][en]'
                  title='Sub title'
                  placeholder='Enter sub title'
                  data={actionData}
                  disabled={disableWhenLoading(navigation)}
                />
                <FormGroup.Editor
                  labelclassname='bg-gray-100'
                  apiKey={ENV.editorApiKey}
                  id='translations[0][description][en]'
                  placeholder='Enter description'
                  data={actionData}
                  disabled={disableWhenLoading(navigation)}
                />
              </div>
              <div className='flex-1'>
                <p className='mb-8 text-gray-500 font-bold'>Finnish</p>
                <FormGroup.Input
                  labelclassname='bg-gray-100'
                  type='text'
                  id='translations[0][title][fi]'
                  title='Title'
                  placeholder='Enter title'
                  data={actionData}
                  disabled={disableWhenLoading(navigation)}
                />
                <FormGroup.Input
                  labelclassname='bg-gray-100'
                  type='text'
                  id='translations[0][sub_title][fi]'
                  title='Sub title'
                  placeholder='Enter sub title'
                  data={actionData}
                  disabled={disableWhenLoading(navigation)}
                />
                <FormGroup.Editor
                  labelclassname='bg-gray-100'
                  apiKey={ENV.editorApiKey}
                  id='translations[0][description][fi]'
                  placeholder='Enter description'
                  data={actionData}
                  disabled={disableWhenLoading(navigation)}
                />
              </div>
            </div>
            <div className='flex-1'>
              <p className='mb-8 text-gray-500 font-bold'>Common inputs</p>
              <FormGroup.Input
                labelclassname='bg-gray-100'
                type='file'
                id='cover_image'
                title='Cover image (MaxHeight: 500, MaxWidth: 800)'
                accept='.jpeg, .png, .jpg, .svg'
                data={actionData}
                disabled={disableWhenLoading(navigation)}
              />
              <FormGroup.Select
                labelclassname='bg-gray-100'
                className='flex-1'
                id='translations[0][service_category]'
                title='Service category'
                data={actionData}
                disabled={disableWhenLoading(navigation)}
              >
                {serviceCategories.map((category) => (
                  <option value={category.value} key={category.value}>
                    {category.name}
                  </option>
                ))}
              </FormGroup.Select>
            </div>
            <CButton
              rounded
              variant='primary'
              type='submit'
              disabled={disableButton(navigation)}
              className='mt-6'
            >
              {handleNavigationStatus(navigation, 'Submit')}
            </CButton>
          </FormGroup>
        </Form>
      </Card>
    </Container>
  );
}

export async function action({
  request,
}: ActionFunctionArgs): Promise<ActionFunction> {
  await queryClient.invalidateQueries({ queryKey: ['services'] });
  return await upsertResource({
    request,
    resource: 'services',
    isFormData: true,
    action: 'create',
  });
}
