import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import Input from './Input';
import Select from './Select';
import TextArea from './TextArea';
import TinyMCEEditor from './TinyMCEEditor';
export default function FormGroup(_a) {
    var children = _a.children;
    return _jsx(_Fragment, { children: children });
}
FormGroup.Select = Select;
FormGroup.TextArea = TextArea;
FormGroup.Input = Input;
FormGroup.Editor = TinyMCEEditor;
