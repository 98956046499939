import { PencilIcon, TrashIcon } from '@heroicons/react/20/solid';
import { ENV } from '../util/constant';
import placeholderImage from '../assets/placeholder-image.png';
import IconButton from './IconButton';
import { useNavigate } from 'react-router-dom';
import { Contact } from '../types/contact';
import {
  EnvelopeIcon,
  PhoneIcon,
  Square3Stack3DIcon,
  UserIcon,
} from '@heroicons/react/24/outline';

type ContactCardProps = {
  showCard?: 'all' | 'delete' | 'view';
  onDelete?: () => void;
  className?: string;
};

export default function ContactCard({
  id,
  avatar,
  firstName,
  lastName,
  translations,
  telephone,
  email,
  showCard,
  onDelete,
  className,
}: Pick<
  Contact,
  | 'id'
  | 'firstName'
  | 'lastName'
  | 'avatar'
  | 'translations'
  | 'telephone'
  | 'email'
> &
  ContactCardProps) {
  const navigate = useNavigate();
  return (
    <figure
      className={`${
        className && className
      } relative w-full md:w-96 h-80 rounded-lg hover:shadow-lg overflow-hidden`}
    >
      {avatar && (
        <div className='rounded-lg'>
          <img
            src={`${
              avatar ? `${ENV.imageBaseUrl}/${avatar}` : placeholderImage
            }`}
            alt={`${firstName} ${lastName}`}
            className='object-cover w-full rounded-t-lg'
          />
        </div>
      )}
      <figcaption className='absolute bottom-0 right-0 left-0 bg-indigo-500 bg-opacity-60 text-white p-4 h-fit'>
        <div className='flex items-center gap-x-2 mb-2'>
          <UserIcon className='h-7 w-7' aria-hidden='true' />
          <p className='italic'>{`${firstName} ${lastName}`}</p>
        </div>
        <div className='flex items-center gap-x-2 mb-2'>
          <Square3Stack3DIcon className='h-7 w-7' aria-hidden='true' />
          <p className='italic'>{translations[0].department}</p>
        </div>
        <div className='flex items-center gap-x-2 mb-2'>
          <PhoneIcon className='h-7 w-7' aria-hidden='true' />
          <p className='italic'>{telephone}</p>
        </div>
        <div className='flex items-center gap-x-2 mb-2'>
          <EnvelopeIcon className='h-7 w-7' aria-hidden='true' />
          <p className='italic'>{email}</p>
        </div>
      </figcaption>
      {showCard && (
        <div className='flex absolute right-4 top-2'>
          {showCard === 'all' && (
            <div className='flex gap-x-2 justify-center items-center bg-indigo-100 rounded-3xl'>
              <IconButton
                onClick={onDelete}
                icon={
                  <TrashIcon
                    className='bg-gray-500 bg-opacity-50 rounded-full h-8 w-8 p-1 text-red-500 hover:text-red-600 hover:bg-white transition-colors'
                    aria-hidden='true'
                  />
                }
              />
              <IconButton
                onClick={() => navigate(`/dashboard/contacts/${id}`)}
                icon={
                  <PencilIcon
                    className='bg-gray-500 bg-opacity-50 rounded-full h-8 w-8 p-1 text-indigo-500 hover:text-indigo-600 hover:bg-white transition-colors'
                    aria-hidden='true'
                  />
                }
              />
            </div>
          )}
          {showCard === 'delete' && avatar && (
            <IconButton
              onClick={onDelete}
              icon={
                <TrashIcon
                  className='bg-gray-500 bg-opacity-50 rounded-full h-8 w-8 p-1 text-red-500 hover:text-red-600 hover:bg-white transition-colors'
                  aria-hidden='true'
                />
              }
            />
          )}
          {showCard === 'view' && (
            <IconButton
              onClick={() => navigate(`/dashboard/contacts/${id}`)}
              className='bg-black rounded-full w-8 h-8 hover:bg-white transition-colors'
              icon={
                <PencilIcon
                  className='bg-gray-500 bg-opacity-50 rounded-full h-8 w-8 p-1 text-indigo-500 hover:text-indigo-600 hover:bg-white transition-colors'
                  aria-hidden='true'
                />
              }
            />
          )}
        </div>
      )}
    </figure>
  );
}
