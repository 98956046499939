import Card from '../../../Card';
import Container from '../../../Container';
import {
  getResources,
  queryClient,
  upsertResource,
} from '../../../../util/request';
import {
  ActionFunction,
  ActionFunctionArgs,
  Form,
  LoaderFunction,
  useActionData,
  useNavigation,
} from 'react-router-dom';
import {
  capitalize,
  CButton,
  disableButton,
  FormGroup,
  handleNavigationStatus,
} from '@maw/utility';
import { disableWhenLoading } from '../../../../util/helper';
import { useQuery } from '@tanstack/react-query';
import HiddenInput from '../../../HiddenInput';

export default function Settings() {
  const actionData: any = useActionData();
  const navigation = useNavigation();

  const { data } = useQuery({
    queryKey: ['maintenance'],
    queryFn: ({ signal }) =>
      getResources({ signal, url: 'operations/maintenance/status' }),
  });

  const maintenanceMode = data?.data;

  return (
    <>
      {maintenanceMode && (
        <Container>
          <Container.Heading>App Settings</Container.Heading>
          <Container.SubHeading>
            Authorized users can adjust settings.
          </Container.SubHeading>
          <Card>
            <Card.Header>Settings</Card.Header>
            <Form method='post' encType='multipart/form-data' noValidate>
              <FormGroup>
                <FormGroup.Select
                  labelclassname='bg-gray-100'
                  className='flex-1'
                  id='mode'
                  title='Mode'
                  defaultValue={maintenanceMode.mode}
                  data={actionData}
                  disabled={disableWhenLoading(navigation)}
                >
                  {['up', 'down'].map((status) => (
                    <option value={status} key={status}>
                      {capitalize(status)}
                    </option>
                  ))}
                </FormGroup.Select>
              </FormGroup>
              <HiddenInput />
              <CButton
                rounded
                variant='primary'
                type='submit'
                disabled={disableButton(navigation)}
                className='mt-6'
              >
                {handleNavigationStatus(navigation, 'Submit')}
              </CButton>
            </Form>
          </Card>
        </Container>
      )}
    </>
  );
}

export async function loader(): Promise<LoaderFunction> {
  return await queryClient.fetchQuery({
    queryKey: ['maintenance'],
    queryFn: ({ signal }) =>
      getResources({ signal, url: 'operations/maintenance/status' }),
  });
}

export async function action({
  request,
}: ActionFunctionArgs): Promise<ActionFunction> {
  await queryClient.invalidateQueries({ queryKey: ['maintenance'] });
  return await upsertResource({
    request,
    resource: 'operations/maintenance/toggle',
    isFormData: true,
    action: 'update',
  });
}
