import { PencilIcon, TrashIcon } from '@heroicons/react/20/solid';
import IconButton from '../../../IconButton';
import Container from '../../../Container';
import { getResources, queryClient } from '../../../../util/request';
import Modal from '../../../Modal';
import {
  isAuthorizedSuper,
  isAuthorizedSuperAdmin,
} from '../../../../util/helper';
import { Link, LoaderFunction } from 'react-router-dom';
import Badge from '../../../Badge';
import useOperation from '../../../../hooks/operation';
import { useQuery } from '@tanstack/react-query';

export default function CompanyCultures() {
  const { data } = useQuery({
    queryKey: ['company-cultures'],
    queryFn: ({ signal }) => getResources({ signal, url: 'company-cultures' }),
  });
  const companyCultures: CompanyCulture[] = data?.data;
  const { id, show, handleDelete, markObjectForDeletion, closeModal } =
    useOperation();

  return (
    <Container>
      <Modal
        show={show}
        onClose={closeModal}
        onAction={() =>
          handleDelete(`company-cultures/${id}`, ['company-cultures'])
        }
      />
      {companyCultures && companyCultures.length > 0 ? (
        <>
          <Container.Heading>Company Cultures</Container.Heading>
          <Container.SubHeading>List.</Container.SubHeading>
          <div className='overflow-x-auto rounded-md'>
            <table className='min-w-full divide-y divide-gray-200 table-auto'>
              <thead className='bg-gray-100'>
                <tr>
                  <th
                    scope='col'
                    className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'
                  >
                    #
                  </th>
                  <th
                    scope='col'
                    className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'
                  >
                    Title
                  </th>
                  <th
                    scope='col'
                    className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'
                  >
                    Description
                  </th>
                  <th
                    scope='col'
                    className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'
                  >
                    Status
                  </th>
                  {isAuthorizedSuperAdmin() && (
                    <th
                      scope='col'
                      className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'
                    >
                      Operation
                    </th>
                  )}
                </tr>
              </thead>
              <tbody className='bg-white divide-y divide-gray-200 p-0'>
                {companyCultures &&
                  companyCultures.length > 0 &&
                  companyCultures.map((companyCulture, index) => (
                    <tr
                      key={companyCulture.id}
                      className='hover:bg-gray-100 transition-all'
                    >
                      <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-600'>
                        {index + 1}
                      </td>
                      <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-600'>
                        {`${companyCulture.translations[0].title.substring(
                          0,
                          10
                        )}...`}
                      </td>
                      <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-600'>
                        {`${companyCulture.translations[0].description.substring(
                          0,
                          10
                        )}...`}
                      </td>
                      <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-600'>
                        <Badge
                          status={companyCulture.translations[0].postStatus}
                        >
                          {companyCulture.translations[0].postStatus}
                        </Badge>
                      </td>

                      {isAuthorizedSuperAdmin() && (
                        <td className='px-6 py-4 flex gap-2'>
                          <Link
                            to={`/dashboard/company-cultures/${companyCulture.id}`}
                          >
                            <IconButton
                              icon={
                                <PencilIcon
                                  className='h-5 w-5 text-indigo-400'
                                  aria-hidden='true'
                                />
                              }
                            />
                          </Link>
                          {isAuthorizedSuper() && (
                            <IconButton
                              onClick={() =>
                                markObjectForDeletion(companyCulture.id)
                              }
                              icon={
                                <TrashIcon
                                  className='h-5 w-5 text-red-400'
                                  aria-hidden='true'
                                />
                              }
                            />
                          )}
                        </td>
                      )}
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </>
      ) : (
        <Container.Heading>No data</Container.Heading>
      )}
    </Container>
  );
}

export async function loader(): Promise<LoaderFunction> {
  return await queryClient.fetchQuery({
    queryKey: ['company-cultures'],
    queryFn: ({ signal }) => getResources({ signal, url: 'company-cultures' }),
  });
}
