import {
  CButton,
  FormGroup,
  disableButton,
  handleNavigationStatus,
} from '@maw/utility';
import {
  ActionFunction,
  ActionFunctionArgs,
  Form,
  useActionData,
  useNavigation,
} from 'react-router-dom';
import Card from '../../../Card';
import { upsertResource, queryClient } from '../../../../util/request';
import Container from '../../../Container';
import { useRedirect } from '../../../../hooks/redirect';
import Alert from '../../../Alert';
import { disableWhenLoading } from '../../../../util/helper';

export default function NewUser() {
  const data: any = useActionData();
  const navigation = useNavigation();

  useRedirect(data, 'users', true);

  return (
    <Container>
      <Alert data={data} />
      <Container.Heading>New User</Container.Heading>
      <Container.SubHeading>
        Authorized user can write the file.
      </Container.SubHeading>
      <Card>
        <Card.Header>New user</Card.Header>
        <Form method='post' noValidate>
          <FormGroup>
            <FormGroup.Input
              labelclassname='bg-gray-100'
              type='text'
              id='first_name'
              title='First name'
              placeholder='Enter first name'
              data={data}
              disabled={disableWhenLoading(navigation)}
            />
            <FormGroup.Input
              labelclassname='bg-gray-100'
              type='text'
              id='last_name'
              title='Last name'
              placeholder='Enter last name'
              data={data}
              disabled={disableWhenLoading(navigation)}
            />
            <FormGroup.Input
              labelclassname='bg-gray-100'
              type='email'
              id='email'
              title='Email'
              placeholder='Email'
              data={data}
              disabled={disableWhenLoading(navigation)}
            />
            <CButton
              rounded
              variant='primary'
              type='submit'
              disabled={disableButton(navigation)}
              className='mt-6'
            >
              {handleNavigationStatus(navigation, 'Submit')}
            </CButton>
          </FormGroup>
        </Form>
      </Card>
    </Container>
  );
}

export async function action({ request }: ActionFunctionArgs): Promise<ActionFunction> {
  await queryClient.invalidateQueries({ queryKey: ['users'] });
  return await upsertResource({
    request,
    resource: 'users',
    isFormData: false,
    action: 'create',
  });
}
