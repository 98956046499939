import { Fragment, useContext } from 'react';
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { classNames } from '@maw/utility';
import { NavLink } from 'react-router-dom';
import getNavigation from '../_nav';
import { AuthContext } from '../store/auth';

export default function Sidebar({ className }: { className?: string }) {
  const authCtx = useContext(AuthContext);
  const navigation = getNavigation(authCtx);
  return (
    <aside
      className={`${
        className && className
      } bg-indigo-600 p-4 overflow-y-scroll`}
    >
      <div className='fixed inset-y-0 left-0 overflow-y-auto bg-indigo-600 px-6 py-6 sm:max-w-sm'>
        <div className='flex items-center justify-between mb-16'>
          <NavLink to='#' className='-m-1.5 p-1.5'>
            <span className='sr-only'>Company Name</span>
            <img
              className='h-8 w-auto'
              src='https://tailwindui.com/img/logos/mark.svg?color=white'
              alt=''
            />
          </NavLink>
        </div>
        <div className='mt-6 flow-root'>
          <div className='-my-6 divide-y divide-gray-500/10'>
            {navigation &&
              navigation.map((item) => (
                <Fragment key={item.name}>
                  {/* Check if item has items */}
                  {item.items.length > 0 ? (
                    <Disclosure as='div' className='-mx-3'>
                      {({ open }) => (
                        <>
                          <DisclosureButton className='flex w-full items-center justify-between py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-white hover:bg-indigo-700'>
                            <div className='flex justify-between items-center gap-4'>
                              {item.icon}
                              {item.name}
                            </div>
                            <ChevronDownIcon
                              className={classNames(
                                open ? 'rotate-180' : '',
                                'h-5 w-5 text-white flex-none'
                              )}
                              aria-hidden='true'
                            />
                          </DisclosureButton>
                          <DisclosurePanel className='mt-2 space-y-2 bg-indigo-500'>
                            {item.items.map((subItem) => (
                              <NavLink
                                key={subItem.name}
                                to={subItem.to}
                                className='block py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-white hover:bg-indigo-700'
                              >
                                <div className='flex items-center gap-2'>
                                  {subItem.icon}
                                  {subItem.name}
                                </div>
                              </NavLink>
                            ))}
                          </DisclosurePanel>
                        </>
                      )}
                    </Disclosure>
                  ) : (
                    // Render NavLink directly for items without sub-items
                    <NavLink
                      to={item.to}
                      className='-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-white hover:bg-indigo-700'
                    >
                      <div className='flex items-center gap-4'>
                        {item.icon}
                        {item.name}
                      </div>
                    </NavLink>
                  )}
                </Fragment>
              ))}
          </div>
        </div>
      </div>
    </aside>
  );
}
