var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { commonButtonClasses } from '../util/constants/colors';
import { getCommonClass } from '../util/helpers';
export default function CButton(_a) {
    var children = _a.children, variant = _a.variant, _b = _a.outline, outline = _b === void 0 ? false : _b, size = _a.size, pill = _a.pill, rounded = _a.rounded, onClick = _a.onClick, type = _a.type, style = _a.style, className = _a.className, disabled = _a.disabled;
    var dynamicClasses = getCommonClass({
        variant: variant,
        outline: outline,
        size: size,
        pill: pill,
        rounded: rounded,
    });
    return (_jsx("button", __assign({ type: type, className: "".concat(dynamicClasses, " ").concat(commonButtonClasses, " ").concat(className ? className : '', " ").concat(disabled ? 'cursor-not-allowed' : ''), style: style && style, onClick: onClick && onClick }, { children: children })));
}
