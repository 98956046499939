import {
  CButton,
  FormGroup,
  disableButton,
  handleNavigationStatus,
} from '@maw/utility';
import {
  ActionFunction,
  ActionFunctionArgs,
  Form,
  LoaderFunction,
  LoaderFunctionArgs,
  useActionData,
  useNavigation,
  useParams,
} from 'react-router-dom';
import Card from '../../../Card';
import { getById, queryClient, upsertResource } from '../../../../util/request';
import Container from '../../../Container';
import Alert from '../../../Alert';
import { useRedirect } from '../../../../hooks/redirect';
import { useQuery } from '@tanstack/react-query';
import { disableWhenLoading } from '../../../../util/helper';
import { postStatus } from '../../../../util/constant';
import HiddenInput from '../../../HiddenInput';
import ErrorAlert from '../../../ErrorAlert';

export default function BlogCategoryDetails() {
  const navigation = useNavigation();
  const actionData: any = useActionData();
  const params = useParams();
  const { data, isLoading } = useQuery({
    queryKey: ['blog-categories', params.id],
    queryFn: ({ signal }) =>
      getById({ signal, resource: `blog-categories/${params.slug}` }),
  });
  const blogCategory: BlogCategory<'translations'> = data?.data;
  useRedirect(actionData, 'blog-categories', true);

  return (
    <>
      {!isLoading && (
        <Container>
          <ErrorAlert data={actionData} />
          <Alert data={actionData} />
          <Container.Heading>Blog Category Details</Container.Heading>
          <Container.SubHeading>
            Authorized user can write the file.
          </Container.SubHeading>
          {blogCategory && (
            <Card>
              <Card.Header>{blogCategory.translations[0].name}</Card.Header>
              <Form method='post' encType='multipart/form-data' noValidate>
                <FormGroup>
                  <FormGroup.Select
                    labelclassname='bg-gray-100'
                    className='flex-1'
                    id='translations[0][post_status]'
                    title='Post status'
                    defaultValue={blogCategory.translations[0].postStatus}
                    data={actionData}
                    disabled={disableWhenLoading(navigation)}
                  >
                    {postStatus.map((status) => (
                      <option value={status.value} key={status.value}>
                        {status.name}
                      </option>
                    ))}
                  </FormGroup.Select>
                  <div className='flex flex-col gap-x-2 md:flex-row md:space-x-2'>
                    <div className='flex-1'>
                      <p className='mb-8 text-gray-500 font-bold'>English</p>
                      <FormGroup.Input
                        labelclassname='bg-gray-100'
                        type='text'
                        id='translations[0][name][en]'
                        title='Name'
                        placeholder='Enter blog category name'
                        defaultValue={blogCategory.translations[0].name}
                        data={actionData}
                        disabled={disableWhenLoading(navigation)}
                      />
                    </div>
                    <div className='flex-1'>
                      <p className='mb-8 text-gray-500 font-bold'>Finnish</p>
                      <FormGroup.Input
                        labelclassname='bg-gray-100'
                        type='text'
                        id='translations[0][name][fi]'
                        title='Name'
                        placeholder='Enter blog category name'
                        defaultValue={blogCategory.translations[1].name}
                        data={actionData}
                        disabled={disableWhenLoading(navigation)}
                      />
                    </div>
                  </div>
                  <HiddenInput />
                  <CButton
                    rounded
                    variant='primary'
                    type='submit'
                    disabled={disableButton(navigation)}
                    className='mt-6'
                  >
                    {handleNavigationStatus(navigation, 'Submit')}
                  </CButton>
                </FormGroup>
              </Form>
            </Card>
          )}
        </Container>
      )}
    </>
  );
}

export async function loader({
  params,
}: LoaderFunctionArgs): Promise<LoaderFunction> {
  return await queryClient.fetchQuery({
    queryKey: ['blog-categories', params.slug],
    queryFn: ({ signal }) =>
      getById({ signal, resource: `blog-categories/${params.slug}` }),
  });
}

export async function action({
  request,
  params,
}: ActionFunctionArgs): Promise<ActionFunction> {
  await queryClient.invalidateQueries({ queryKey: ['blog-categories'] });
  return await upsertResource({
    request,
    resource: `blog-categories/${params.slug}`,
    isFormData: true,
    action: 'update',
  });
}
