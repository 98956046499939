import { STATUS_CODE } from '@maw/utility';

type ErrorAlertProps = {
  data: {
    statusCode: number;
    error?:
      | { message: string[] | string }
      | { [key: string]: string | string[] };
  };
};

export default function ErrorAlert({ data }: ErrorAlertProps) {
  const errors: string[] = [];

  const processMessage = (message: any) => {
    if (typeof message === 'string') {
      errors.push(message);
    } else if (Array.isArray(message)) {
      message.forEach(processMessage);
    } else if (typeof message === 'object') {
      Object.values(message).forEach(processMessage);
    }
  };

  if (data && data.statusCode) {
    if (
      [STATUS_CODE.badRequest, STATUS_CODE.unprocessableContent].includes(
        data.statusCode
      ) &&
      data.error?.message
    ) {
      const { message } = data.error;
      processMessage(message);

      return (
        <div className='p-4 rounded-md my-4 bg-red-200'>
          <ul className='flex flex-col'>
            <h1 className='text-red-700 mb-2 text-[1.5rem]'>Errors</h1>
            {errors.length > 0 &&
              errors.map((error, index) => (
                <li key={index} className='text-red-700'>
                  {`${index + 1}. ${error}`}
                </li>
              ))}
          </ul>
        </div>
      );
    }
  }

  return null;
}
