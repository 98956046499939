import {
  CButton,
  FormGroup,
  disableButton,
  handleNavigationStatus,
} from '@maw/utility';
import {
  ActionFunctionArgs,
  Form,
  LoaderFunction,
  useActionData,
  useNavigation,
} from 'react-router-dom';
import Card from '../../../Card';
import { queryClient, upsertResource } from '../../../../util/request';
import Container from '../../../Container';
import Alert from '../../../Alert';
import { useRedirect } from '../../../../hooks/redirect';
import { disableWhenLoading } from '../../../../util/helper';
import ErrorAlert from '../../../ErrorAlert';

export default function NewBlogCategory() {
  const actionData: any = useActionData();
  const navigation = useNavigation();

  useRedirect(actionData, 'blog-categories', true);

  return (
    <Container>
      <ErrorAlert data={actionData} />
      <Alert data={actionData} />
      <Container.Heading>New blog category</Container.Heading>
      <Container.SubHeading>
        Authorized user can write the file.
      </Container.SubHeading>
      <Card>
        <Card.Header>New blog category</Card.Header>
        <Form method='post' encType='multipart/form-data' noValidate>
          <FormGroup>
            <div className='flex flex-col gap-x-2 md:flex-row md:space-x-2'>
              <div className='flex-1'>
                <p className='mb-8 text-gray-500 font-bold'>English</p>
                <FormGroup.Input
                  
                  labelclassname='bg-gray-100'
                  type='text'
                  id='translations[0][name][en]'
                  title='Name'
                  placeholder='Enter blog category name'
                  data={actionData}
                  disabled={disableWhenLoading(navigation)}
                />
              </div>
              <div className='flex-1'>
                <p className='mb-8 text-gray-500 font-bold'>Finnish</p>
                <FormGroup.Input
                  
                  labelclassname='bg-gray-100'
                  type='text'
                  id='translations[0][name][fi]'
                  title='Name'
                  placeholder='Enter blog category name'
                  data={actionData}
                  disabled={disableWhenLoading(navigation)}
                />
              </div>
            </div>
            <CButton
              rounded
              variant='primary'
              type='submit'
              disabled={disableButton(navigation)}
              className='mt-6'
            >
              {handleNavigationStatus(navigation, 'Submit')}
            </CButton>
          </FormGroup>
        </Form>
      </Card>
    </Container>
  );
}

export async function action({
  request,
}: ActionFunctionArgs): Promise<LoaderFunction> {
  await queryClient.invalidateQueries({ queryKey: ['blog-categories'] });
  return await upsertResource({
    request,
    resource: 'blog-categories',
    isFormData: true,
    action: 'create',
  });
}
