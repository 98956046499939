import {
  CButton,
  FormGroup,
  disableButton,
  handleNavigationStatus,
} from '@maw/utility';
import {
  ActionFunction,
  ActionFunctionArgs,
  Form,
  useActionData,
  useNavigation,
} from 'react-router-dom';
import Card from '../../../Card';
import { queryClient, upsertResource } from '../../../../util/request';
import Container from '../../../Container';
import { useRedirect } from '../../../../hooks/redirect';
import Alert from '../../../Alert';
import { disableWhenLoading } from '../../../../util/helper';

export default function NewTrustBadge() {
  const actionData: any = useActionData();
  const navigation = useNavigation();

  useRedirect(actionData, 'trust-badges', true);

  return (
    <Container>
      <Alert data={actionData} />
      <Container.Heading>New Trust Badge</Container.Heading>
      <Container.SubHeading>
        Authorized user can write file.
      </Container.SubHeading>
      <Card>
        <Card.Header>New trust badge</Card.Header>
        <Form method='post' encType='multipart/form-data' noValidate>
          <FormGroup>
            <FormGroup.Input
              labelclassname='bg-gray-100'
              className='flex-1'
              type='text'
              id='name'
              title='Badge name'
              placeholder='E.g Hesbuger'
              data={actionData}
              disabled={disableWhenLoading(navigation)}
            />
            <FormGroup.Input
              labelclassname='bg-gray-100'
              className='flex-1'
              type='file'
              id='image'
              title='Image (MaxHeight: 500, MaxWidth: 500)'
              accept='.jpeg, .png, .jpg, .svg'
              data={actionData}
              disabled={disableWhenLoading(navigation)}
            />
            <CButton
              rounded
              variant='primary'
              type='submit'
              disabled={disableButton(navigation)}
              className='mt-6'
            >
              {handleNavigationStatus(navigation, 'Submit')}
            </CButton>
          </FormGroup>
        </Form>
      </Card>
    </Container>
  );
}

export async function action({
  request,
}: ActionFunctionArgs): Promise<ActionFunction> {
  await queryClient.invalidateQueries({ queryKey: ['trust-badges'] });
  return await upsertResource({
    request,
    resource: 'trust-badges',
    isFormData: true,
    action: 'create',
  });
}
