import {
  CButton,
  FormGroup,
  disableButton,
  handleNavigationStatus,
} from '@maw/utility';
import {
  ActionFunction,
  ActionFunctionArgs,
  Form,
  LoaderFunction,
  LoaderFunctionArgs,
  useActionData,
  useNavigation,
  useParams,
} from 'react-router-dom';
import Card from '../../../Card';
import { getById, queryClient, upsertResource } from '../../../../util/request';
import Container from '../../../Container';
import Alert from '../../../Alert';
import { postStatus } from '../../../../util/constant';
import { useRedirect } from '../../../../hooks/redirect';
import { useQuery } from '@tanstack/react-query';
import { disableWhenLoading } from '../../../../util/helper';
import HiddenInput from '../../../HiddenInput';
import TestimonialCard from '../../../TestimonialCard';
import useOperation from '../../../../hooks/operation';
import Modal from '../../../Modal';

export default function TestimonialDetails() {
  const navigation = useNavigation();
  const params = useParams();
  const actionData: any = useActionData();
  const { data } = useQuery({
    queryKey: ['testimonials', params.id],
    queryFn: ({ signal }) =>
      getById({ signal, resource: `testimonials/${params.id}` }),
  });
  const testimonial: Testimonial = data?.data;

  useRedirect(actionData, 'testimonials', true);
  const { id, show, handleDelete, markObjectForDeletion, closeModal } =
    useOperation();

  return (
    <Container>
      <Alert data={actionData} />
      <Modal
        show={show}
        onClose={closeModal}
        onAction={() =>
          handleDelete(`testimonials/${id}/images/delete`, ['testimonials', id])
        }
      />
      <Container.Heading>Testimonial Details</Container.Heading>
      <Container.SubHeading>
        Authorized user can write the file.
      </Container.SubHeading>
      {testimonial && testimonial.photo && (
        <TestimonialCard
          className='my-4'
          firstName={testimonial.firstName}
          lastName={testimonial.lastName}
          id={testimonial.id}
          onDelete={() => markObjectForDeletion(testimonial.id)}
          allowDelete
          key={testimonial.id}
          photo={testimonial.photo}
        />
      )}
      {testimonial && (
        <Card>
          <Card.Header>{`${testimonial.firstName} ${testimonial.lastName}`}</Card.Header>
          <Form method='post' encType='multipart/form-data' noValidate>
            <FormGroup>
              <div className='flex flex-col gap-x-2 md:flex-row md:space-x-2'>
                <FormGroup.Input
                  labelclassname='bg-gray-100'
                  className='flex-1'
                  type='text'
                  id='first_name'
                  title='First name'
                  placeholder='Enter first name'
                  defaultValue={testimonial.firstName}
                  data={actionData}
                  disabled={disableWhenLoading(navigation)}
                />
                <FormGroup.Input
                  labelclassname='bg-gray-100'
                  className='flex-1'
                  type='text'
                  id='last_name'
                  title='Last name'
                  placeholder='Enter last name'
                  defaultValue={testimonial.lastName}
                  data={actionData}
                  disabled={disableWhenLoading(navigation)}
                />
              </div>
              <div className='flex flex-col gap-x-2 md:flex-row md:space-x-2'>
                <FormGroup.Input
                  labelclassname='bg-gray-100'
                  className='flex-1'
                  type='text'
                  id='company_name'
                  title='Company name'
                  placeholder='Enter the company name'
                  defaultValue={testimonial.companyName}
                  data={actionData}
                  disabled={disableWhenLoading(navigation)}
                />
                <FormGroup.Input
                  labelclassname='bg-gray-100'
                  className='flex-1'
                  type='text'
                  id='title'
                  title='Title'
                  placeholder='E.g CEO'
                  defaultValue={testimonial.title}
                  data={actionData}
                  disabled={disableWhenLoading(navigation)}
                />
              </div>
              <div className='flex flex-col gap-x-2 md:flex-row md:space-x-2'>
                <FormGroup.Select
                  labelclassname='bg-gray-100'
                  className='flex-1'
                  id='post_status'
                  title='Post status'
                  defaultValue={testimonial.postStatus}
                  data={actionData}
                  disabled={disableWhenLoading(navigation)}
                >
                  {postStatus.map((status) => (
                    <option value={status.value} key={status.value}>
                      {status.name}
                    </option>
                  ))}
                </FormGroup.Select>
                <FormGroup.Input
                  labelclassname='bg-gray-100'
                  className='flex-1'
                  type='file'
                  id='photo'
                  title='Photo (MaxHeight: 500, MaxWidth: 800)'
                  accept='.jpeg, .png, .jpg, .svg'
                  data={actionData}
                  disabled={disableWhenLoading(navigation)}
                />
              </div>
              <FormGroup.TextArea
                labelclassname='bg-gray-100'
                className='flex-1'
                id='description'
                title='Description'
                defaultValue={testimonial.description}
                data={actionData}
                disabled={disableWhenLoading(navigation)}
              />
              <HiddenInput />
              <CButton
                rounded
                variant='primary'
                type='submit'
                disabled={disableButton(navigation)}
                className='mt-6'
              >
                {handleNavigationStatus(navigation, 'Submit')}
              </CButton>
            </FormGroup>
          </Form>
        </Card>
      )}
    </Container>
  );
}

export async function loader({
  params,
}: LoaderFunctionArgs): Promise<LoaderFunction> {
  return await queryClient.fetchQuery({
    queryKey: ['testimonials', params.id],
    queryFn: ({ signal }) =>
      getById({ signal, resource: `testimonials/${params.id}` }),
  });
}

export async function action({
  request,
  params,
}: ActionFunctionArgs): Promise<ActionFunction> {
  await queryClient.invalidateQueries({
    queryKey: ['testimonials'],
  });
  return await upsertResource({
    request,
    resource: `testimonials/${params.id}`,
    isFormData: true,
    action: 'update',
  });
}
