import { createContext } from 'react';
import { ContextUser } from '../types/user';

export const AuthContext = createContext<{
  user: ContextUser | undefined;
  token: string | undefined;
  login: (user: ContextUser, token: string) => void;
  logout: () => void;
}>({
  user: {
    id: '',
    firstName: '',
    lastName: '',
    avatar: '',
    role: 'user',
    status: 'pending'
  },
  token: '',
  login: (user: ContextUser, token: string) => {},
  logout: () => {},
});
