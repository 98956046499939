import { PencilIcon, TrashIcon } from '@heroicons/react/20/solid';
import IconButton from '../../../IconButton';
import Container from '../../../Container';
import { getResources, queryClient } from '../../../../util/request';
import Modal from '../../../Modal';
import { isAuthorizedSuperAdmin } from '../../../../util/helper';
import { Link, LoaderFunction } from 'react-router-dom';
import Badge from '../../../Badge';
import useOperation from '../../../../hooks/operation';
import { useQuery } from '@tanstack/react-query';

export default function Customers() {
  const { data } = useQuery({
    queryKey: ['customers'],
    queryFn: ({ signal }) => getResources({ signal, url: 'customers' }),
  });
  const customers: Customer[] = data?.data;
  const { id, show, handleDelete, markObjectForDeletion, closeModal } =
    useOperation();

  return (
    <Container>
      <Modal
        show={show}
        onClose={closeModal}
        onAction={() => handleDelete(`customers/${id}`, ['customers'])}
      />
      {customers && customers.length > 0 ? (
        <>
          <Container.Heading>Customers</Container.Heading>
          <Container.SubHeading>List of customers.</Container.SubHeading>
          <div className='overflow-x-auto rounded-md'>
            <table className='min-w-full divide-y divide-gray-200 table-auto'>
              <thead className='bg-gray-100'>
                <tr>
                  <th
                    scope='col'
                    className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'
                  >
                    #
                  </th>
                  <th
                    scope='col'
                    className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'
                  >
                    Company name
                  </th>
                  <th
                    scope='col'
                    className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'
                  >
                    Contact person
                  </th>
                  <th
                    scope='col'
                    className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'
                  >
                    Email
                  </th>
                  <th
                    scope='col'
                    className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'
                  >
                    Post status
                  </th>
                  <th
                    scope='col'
                    className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'
                  >
                    Business number
                  </th>
                  {isAuthorizedSuperAdmin() && (
                    <th
                      scope='col'
                      className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'
                    >
                      Operation
                    </th>
                  )}
                </tr>
              </thead>
              <tbody className='bg-white divide-y divide-gray-200 p-0'>
                {customers.length > 0 &&
                  customers.map((customer, index) => (
                    <tr
                      key={customer.id}
                      className='hover:bg-gray-100 transition-all'
                    >
                      <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-600'>
                        {index + 1}
                      </td>
                      <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-600'>
                        {customer.companyName}
                      </td>
                      <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-600'>
                        {customer.contactPerson}
                      </td>
                      <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-600'>
                        {customer.email}
                      </td>
                      <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-600'>
                        <Badge status={customer.postStatus}>
                          {customer.postStatus}
                        </Badge>
                      </td>

                      <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-600'>
                        {customer.businessNumber}
                      </td>
                      {isAuthorizedSuperAdmin() && (
                        <td className='px-6 py-4 flex gap-2'>
                          <Link to={`/dashboard/customers/${customer.id}`}>
                            <IconButton
                              icon={
                                <PencilIcon
                                  className='h-5 w-5 text-indigo-400'
                                  aria-hidden='true'
                                />
                              }
                            />
                          </Link>
                          <IconButton
                            onClick={() => markObjectForDeletion(customer.id)}
                            icon={
                              <TrashIcon
                                className='h-5 w-5 text-red-400'
                                aria-hidden='true'
                              />
                            }
                          />
                        </td>
                      )}
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </>
      ) : (
        <Container.Heading>No data</Container.Heading>
      )}
    </Container>
  );
}

export async function loader(): Promise<LoaderFunction> {
  return await queryClient.fetchQuery({
    queryKey: ['customers'],
    queryFn: ({ signal }) => getResources({ signal, url: 'customers' }),
  });
}
