var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Editor } from '@tinymce/tinymce-react';
import { INPUTSTYLES } from '../../util/constants/status';
import { ErrorManager } from '../../util/error-manager';
var TinyMCEEditor = function (_a) {
    var id = _a.id, data = _a.data, className = _a.className, apiKey = _a.apiKey, title = _a.title, isRequired = _a.isRequired, initialValue = _a.initialValue;
    var errorInstance = new ErrorManager(false);
    errorInstance.hasError(data, id);
    return (_jsxs("div", __assign({ className: "".concat(className && className, " ").concat(INPUTSTYLES.parent) }, { children: [_jsx("label", __assign({ htmlFor: id, className: "".concat(INPUTSTYLES.label) }, { children: title })), _jsx(Editor, { apiKey: apiKey, initialValue: initialValue, tagName: id, id: id, textareaName: id, init: {
                    width: '100%',
                    menubar: true,
                    plugins: 'anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount',
                    toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat',
                } }), errorInstance.getErrorMessage(id)] })));
};
export default TinyMCEEditor;
