import { ContactDepartment } from '../types/contact';
import { EmploymentStatus } from '../types/employee';
import { Gender, PermitStatus, Role, UserStatus } from '../types/user';

export const ENV = {
  adminBaseUrl: process.env.REACT_APP_ADMIN_BASE_URL,
  imageBaseUrl: process.env.REACT_APP_IMAGE_BASE_URL,
  assetBaseUrl: process.env.REACT_APP_GLOBAL_ASSET_BASE_URL,
  requestToken: process.env.REACT_APP_CLIENT_TOKEN,
  editorApiKey: process.env.REACT_APP_TINYMCE_API_KEY,
};

export const ROLES: { [key: string]: Role } = {
  admin: 'admin',
  super: 'super',
  user: 'user',
};

export const GENDER: { [key: string]: Gender } = {
  male: 'male',
  female: 'female',
  other: 'other',
};

export const PERMIT_STATUS: { [key: string]: PermitStatus } = {
  A: 'A',
  B: 'B',
  C: 'C',
  PR: 'PR',
};

export const USER_STATUS: { [key: string]: UserStatus } = {
  clear: 'clear',
  pending: 'pending',
  suspended: 'suspended',
  closed: 'closed',
  banned: 'banned',
};

export const POST_STATUS: { [key: string]: PostStatus } = {
  published: 'published',
  draft: 'draft',
  archived: 'archived',
};

export const SERVICE_CATEGORIES: { [key: string]: ServiceCategory } = {
  home: 'home',
  restaurant: 'restaurant',
  office: 'office',
  other: 'other',
  supermarket: 'supermarket',
};

export const CONTACT_DEPARTMENT: { [key: string]: ContactDepartment } = {
  sales: 'sales',
  account: 'account',
  service: 'service',
};

export const EMPLOYMENT_STATUS: { [key: string]: EmploymentStatus } = {
  pending: 'pending',
  temporary: 'temporary',
  onCall: 'on-call',
  sacked: 'sacked',
  permanent: 'permanent',
  suspended: 'suspended',
  resigned: 'resigned',
};

export const postStatus = [
  { name: 'Select status', value: '' },
  { name: 'Published', value: POST_STATUS.published },
  { name: 'Draft', value: POST_STATUS.draft },
  { name: 'Archived', value: POST_STATUS.archived },
];

export const serviceCategories = [
  { name: 'Select a category', value: '' },
  { name: 'Home', value: SERVICE_CATEGORIES.home },
  { name: 'Restaurant', value: SERVICE_CATEGORIES.restaurant },
  { name: 'Supermarket', value: SERVICE_CATEGORIES.supermarket },
  { name: 'Office', value: SERVICE_CATEGORIES.office },
  { name: 'Other', value: SERVICE_CATEGORIES.other },
];

export const roles = [
  { name: 'Select a role', value: '' },
  { name: 'Admin', value: ROLES.admin },
  { name: 'Super', value: ROLES.super },
  { name: 'User', value: ROLES.user },
];

export const employmentStatus = [
  { name: 'Select a status', value: '' },
  { name: 'Permanent', value: EMPLOYMENT_STATUS.permanent },
  { name: 'Temporary', value: EMPLOYMENT_STATUS.temporary },
  { name: 'Sacked', value: EMPLOYMENT_STATUS.sacked },
  { name: 'On call', value: EMPLOYMENT_STATUS.onCall },
  { name: 'Pending', value: EMPLOYMENT_STATUS.pending },
  { name: 'Suspended', value: EMPLOYMENT_STATUS.suspended },
  { name: 'Resigned', value: EMPLOYMENT_STATUS.resigned },
];

export const userStatus = [
  { name: 'Select a status', value: '' },
  { name: 'Clear', value: USER_STATUS.clear },
  { name: 'Banned', value: USER_STATUS.banned },
  { name: 'Suspended', value: USER_STATUS.suspended },
  { name: 'Closed', value: USER_STATUS.closed },
  { name: 'Pending', value: USER_STATUS.pending },
];

export const gender = [
  { name: 'Select a gender', value: '' },
  { name: 'Male', value: GENDER.male },
  { name: 'Female', value: GENDER.female },
  { name: 'Other', value: GENDER.other },
];

export const permitStatus = [
  { name: 'Select a permit type', value: '' },
  { name: 'A', value: PERMIT_STATUS.A },
  { name: 'B', value: PERMIT_STATUS.B },
  { name: 'C', value: PERMIT_STATUS.C },
  { name: 'PR', value: PERMIT_STATUS.PR },
];

export const contactDepartment = [
  { name: 'Select a department', value: '' },
  { name: 'Sales', value: CONTACT_DEPARTMENT.sales },
  { name: 'Account', value: CONTACT_DEPARTMENT.account },
  { name: 'Service', value: CONTACT_DEPARTMENT.service },
];
