export default function Footer({ className }: { className?: string }) {
  return (
    <footer
      className={`${
        className && className
      } text-center border-y-2 border-gray-200 p-4`}
    >
      <small>&copy; {new Date().getFullYear()} </small>
    </footer>
  );
}
