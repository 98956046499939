import { Link, useRouteError } from 'react-router-dom';
import { STATUS_CODE } from '@maw/utility';
import NotFound from './views/pages/NotFound';

export default function ErrorBoundary() {
  const error: any = useRouteError();

  if (error.status === STATUS_CODE.notFound) {
    return <NotFound />;
  }
  return (
    <main className='min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8'>
      <div className='text-center'>
        <p className='text-base font-semibold text-indigo-600'>
          {error.status}
        </p>
        <h1 className='mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl'>
          Error!
        </h1>
        <p className='mt-6 text-base leading-7 text-gray-600'>
          Sorry, an error has occurred.
        </p>
        <div className='mt-10 flex items-center justify-center gap-x-6'>
          <Link
            to='/'
            className='rounded-md inline-block text-center bg-indigo-500 px-3.5 py-2.5 text-lg font-semibold text-white hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white ring-offset-2 ring-2'
          >
            Go back home
          </Link>
        </div>
      </div>
    </main>
  );
}
