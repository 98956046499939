import { Navigation } from 'react-router-dom';
import { UserStatus } from '../types/user';
import { ROLES, USER_STATUS } from './constant';
import { capitalize } from '@maw/utility';
import { EmploymentStatus } from '../types/employee';

interface BlogCategorySelectable {
  id: string;
  name: string;
}

interface BlogCategorySelect {
  id: string;
  name: string;
}

export function getDataFromStorage() {
  const userData = sessionStorage.getItem('user');
  return userData ? JSON.parse(userData) : null;
}

export function clearSession() {
  sessionStorage.removeItem('user');
  window.location.replace('/');
}

export function reloadPage() {
  window.location.reload();
}

export function isAuthorized() {
  const data = getDataFromStorage();
  if (data) {
    return (
      data.token.length &&
      [ROLES.user, ROLES.admin, ROLES.super].includes(data.user.role) &&
      data.user.status === USER_STATUS.clear
    );
  }
  return false;
}

export function isAuthorizedSuper() {
  const data = getDataFromStorage();
  if (data) {
    return (
      data.token.length &&
      [ROLES.super].includes(data.user.role) &&
      data.user.status === USER_STATUS.clear
    );
  }
  return false;
}

export function isAuthorizedAdmin() {
  const data = getDataFromStorage();
  if (data) {
    return (
      data.token.length &&
      [ROLES.admin].includes(data.user.role) &&
      data.user.status === USER_STATUS.clear
    );
  }
  return false;
}

export function isAuthorizedSuperAdmin() {
  const data = getDataFromStorage();
  if (data) {
    return (
      data.token.length &&
      [ROLES.admin, ROLES.super].includes(data.user.role) &&
      data.user.status === USER_STATUS.clear
    );
  }
  return false;
}

export function isAuthorizedUser() {
  const data = getDataFromStorage();
  if (data) {
    return (
      data.token.length &&
      [ROLES.user].includes(data.user.role) &&
      data.user.status === USER_STATUS.clear
    );
  }
  return false;
}

export function disableWhenLoading(navigation: Navigation) {
  return navigation.state === 'loading' || navigation.state === 'submitting';
}

export function getBlogCategoriesForSelectInput(
  data: BlogCategorySelectable[]
) {
  const initialArray = [{ id: '', name: 'Select a blog category' }];
  const transformedData: BlogCategorySelect[] = [];
  if (data) {
    data.forEach((category) => {
      transformedData.push({
        id: category.id,
        name: capitalize(category.name),
      });
    });
  }
  return [...initialArray, ...transformedData];
}

export function handleBadgeColor(
  status: UserStatus | PostStatus | EmploymentStatus
) {
  switch (status) {
    case 'clear': {
      return 'rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-700/10';
    }
    case 'banned': {
      return 'rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-700/10';
    }
    case 'closed': {
      return 'rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-700/10';
    }
    case 'draft': {
      return 'rounded-md bg-indigo-50 px-2 py-1 text-xs font-medium text-indigo-700 ring-1 ring-inset ring-indigo-700/10';
    }
    case 'pending': {
      return 'rounded-md bg-yellow-50 px-2 py-1 text-xs font-medium text-yellow-700 ring-1 ring-inset ring-yellow-700/10';
    }
    case 'published': {
      return 'rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-700/10';
    }
    case 'suspended': {
      return 'rounded-md bg-yellow-50 px-2 py-1 text-xs font-medium text-yellow-700 ring-1 ring-inset ring-yellow-700/10';
    }
    case 'sacked': {
      return 'rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-700/10';
    }
    case 'permanent': {
      return 'rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-700/10';
    }
    case 'temporary': {
      return 'rounded-md bg-yellow-50 px-2 py-1 text-xs font-medium text-yellow-700 ring-1 ring-inset ring-yellow-700/10';
    }
    case 'on-call': {
      return 'rounded-md bg-indigo-50 px-2 py-1 text-xs font-medium text-indigo-700 ring-1 ring-inset ring-indigo-700/10';
    }
    case 'resigned': {
      return 'rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-700/10';
    }
    default:
      return 'rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-700 ring-1 ring-inset ring-gray-700/10';
  }
}
