import {
  CButton,
  FormGroup,
  disableButton,
  handleNavigationStatus,
} from '@maw/utility';
import {
  ActionFunction,
  ActionFunctionArgs,
  Form,
  useActionData,
  useNavigation,
} from 'react-router-dom';
import Card from '../../../Card';
import { upsertResource, queryClient } from '../../../../util/request';
import Container from '../../../Container';
import { useRedirect } from '../../../../hooks/redirect';
import Alert from '../../../Alert';
import { disableWhenLoading } from '../../../../util/helper';

export default function NewTestimonial() {
  const actionData: any = useActionData();
  const navigation = useNavigation();

  useRedirect(actionData, 'testimonials', true);

  return (
    <Container>
      <Alert data={actionData} />
      <Container.Heading>New Testimonial</Container.Heading>
      <Container.SubHeading>
        Authorized user can write the file.
      </Container.SubHeading>
      <Card>
        <Card.Header>New testimonial</Card.Header>
        <Form method='post' encType='multipart/form-data' noValidate>
          <FormGroup>
            <div className='flex flex-col gap-x-2 md:flex-row md:space-x-2'>
              <FormGroup.Input
                labelclassname='bg-gray-100'
                className='flex-1'
                type='text'
                id='first_name'
                title='First name'
                placeholder='Enter first name'
                data={actionData}
                disabled={disableWhenLoading(navigation)}
              />
              <FormGroup.Input
                labelclassname='bg-gray-100'
                className='flex-1'
                type='text'
                id='last_name'
                title='Last name'
                placeholder='Enter last name'
                data={actionData}
                disabled={disableWhenLoading(navigation)}
              />
            </div>
            <div className='flex flex-col gap-x-2 md:flex-row md:space-x-2'>
              <FormGroup.Input
                labelclassname='bg-gray-100'
                className='flex-1'
                type='text'
                id='company_name'
                title='Company name'
                placeholder='Enter company name'
                data={actionData}
                disabled={disableWhenLoading(navigation)}
              />
              <FormGroup.Input
                labelclassname='bg-gray-100'
                className='flex-1'
                type='text'
                id='title'
                title='Title'
                placeholder='e.g. CEO'
                data={actionData}
                disabled={disableWhenLoading(navigation)}
              />
            </div>
            <FormGroup.Input
              labelclassname='bg-gray-100'
              className='flex-1'
              type='file'
              id='photo'
              title='Photo (MaxHeight: 500, MaxWidth: 800)'
              accept='.jpeg, .png, .jpg, .svg'
              data={actionData}
              disabled={disableWhenLoading(navigation)}
            />
            <FormGroup.TextArea
              labelclassname='bg-gray-100'
              className='flex-1'
              id='description'
              title='Description'
              placeholder='Enter your review'
              data={actionData}
              disabled={disableWhenLoading(navigation)}
            />
            <CButton
              rounded
              variant='primary'
              type='submit'
              disabled={disableButton(navigation)}
              className='mt-6'
            >
              {handleNavigationStatus(navigation, 'Submit')}
            </CButton>
          </FormGroup>
        </Form>
      </Card>
    </Container>
  );
}

export async function action({ request }: ActionFunctionArgs): Promise<ActionFunction> {
  await queryClient.invalidateQueries({ queryKey: ['testimonials'] });
  return await upsertResource({
    request,
    resource: 'testimonials',
    isFormData: true,
    action: 'create',
  });
}
