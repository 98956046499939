import { Link } from 'react-router-dom';
import { ENV } from '../util/constant';

import placeholderImage from '../assets/placeholder-image.png';
import { CButton } from '@maw/utility';

type TrustBadgeProps = {
  id: string;
  image: string;
  name: string;
  onDelete: () => void;
} & TrustBadge;

export default function TrustBadge({
  id,
  image,
  name,
  onDelete,
}: TrustBadgeProps) {
  return (
    <div className='relative'>
      <div className='flex gap-x-4'>
        <CButton
          onClick={onDelete}
          className='bg-red-400 text-sm text-white rounded-3xl p-0'
        >
          Delete
        </CButton>
        <Link to={`/dashboard/trust-badges/${id}`}>
          <CButton className='bg-indigo-400 text-sm text-white rounded-3xl p-0'>
            View
          </CButton>
        </Link>
      </div>
      <img
        src={`${!image ? placeholderImage : ENV.imageBaseUrl}/${image}`}
        alt={name}
        className='sm:w-96 sm:h-36 object-cover'
      />
    </div>
  );
}
