var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Link } from 'react-router-dom';
import { getCommonClass } from '../util/helpers';
import { commonButtonClasses } from '../util/constants/colors';
export default function CLink(_a) {
    var children = _a.children, variant = _a.variant, size = _a.size, pill = _a.pill, rounded = _a.rounded, href = _a.href, _b = _a.outline, outline = _b === void 0 ? false : _b, onClick = _a.onClick, style = _a.style, className = _a.className;
    var dynamicClasses = getCommonClass({
        variant: variant,
        outline: outline,
        size: size,
        pill: pill,
        rounded: rounded,
    });
    if (href.startsWith('https') || href.startsWith('http')) {
        return (_jsx("a", __assign({ href: href, className: "".concat(dynamicClasses, " ").concat(commonButtonClasses, " ").concat(className ? className : ''), style: style && style, onClick: onClick && onClick }, { children: children })));
    }
    return (_jsx(Link, __assign({ to: href, className: "".concat(dynamicClasses, " ").concat(commonButtonClasses, " ").concat(className ? className : ''), style: style && style, onClick: onClick && onClick }, { children: children })));
}
